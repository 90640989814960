/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.o2a-icon {
  padding-bottom: 6px; }

.o2a-icon > span:last-child {
  padding-left: 3px; }

.o2a-icon, .o2a-icon [class^="o2f-"] {
  color: #2485c4; }

.o2a-icon:hover {
  text-decoration: none; }

.o2a-icon:hover > span:last-child {
  text-decoration: underline; }

.o2a-icon:hover [class^="o2f-"] {
  color: #005291; }

.o2a-icon [class^="o2f-"] {
  width: auto;
  height: auto;
  line-height: 1; }

/* BALL with default blue bg color */
.o2-ball {
  border-radius: 50%;
  background-color: #005177; }

.o2-ball--1 {
  width: 3px;
  height: 3px; }

.o2-ball--2 {
  width: 6px;
  height: 6px; }

.o2-ball--3 {
  width: 12px;
  height: 12px; }

.o2-ball--4 {
  width: 18px;
  height: 18px; }

.o2-ball--5 {
  width: 36px;
  height: 36px; }

/* Divider for HR element */
.o2-divider {
  position: relative;
  display: block;
  margin-top: 12px;
  margin-bottom: 12px; }

.o2-divider--line {
  border: 0;
  height: 0;
  border-top: 1px solid #C8C8C8; }

.o2-divider--gradient {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #C8C8C8, rgba(0, 0, 0, 0.75), #C8C8C8); }

.o2-divider.o2-divider__arrow {
  margin-top: 48px;
  margin-bottom: 84px; }

.o2-divider.o2-divider__arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  left: 45%;
  bottom: -24px;
  border-right: 1px solid #C8C8C8;
  border-bottom: 1px solid #C8C8C8;
  background-color: white;
  transform: scale(2, 1) rotate(45deg); }

.o2-divider.o2-divider__arrow--gradient:after {
  content: '';
  position: absolute;
  display: block;
  width: 48px;
  height: 48px;
  left: 45%;
  bottom: -24px;
  border-right: 1px solid rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  background-color: white;
  transform: scale(2, 1) rotate(45deg); }

.o2-switch .o2-switch__input {
  display: none; }

.o2-switch .o2-switch__input + label {
  cursor: pointer;
  position: relative;
  padding: 0 0 0 52px;
  display: inline; }

.o2-switch .o2-switch__input + label::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 40px;
  height: 20px;
  transition: all 0.3s;
  border-radius: 2px;
  background: #dddddd; }

.o2-switch .o2-switch__input + label::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 18px;
  height: 16px;
  margin: 2px 0 0 2px;
  background: white;
  transition: all 0.3s;
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35); }

.o2-switch .o2-switch__input:checked + label::after {
  left: 18px;
  background: white; }

.o2-switch .o2-switch__input:checked + label::before {
  background-color: #0093d5; }

.o2-switch .o2-switch__input:not(:checked) + label::after {
  background: white; }

.o2-switch .o2-switch__input:disabled + label {
  cursor: not-allowed; }

.o2-switch .o2-switch__input:disabled + label::after {
  background-color: #eeeeee; }

/* right o2-switch */
.o2-switch.o2-switch--reverse .o2-switch__input + label {
  padding: 0 52px 0 0; }

.o2-switch.o2-switch--reverse .o2-switch__input + label::before {
  left: auto;
  left: initial;
  right: 0; }

.o2-switch.o2-switch--reverse .o2-switch__input + label::after {
  left: auto;
  left: initial;
  right: 20px; }

.o2-switch.o2-switch--reverse .o2-switch__input:checked + label::after {
  left: auto;
  left: initial;
  right: 2px; }

/* success switch */
.o2-switch.o2-switch--success .o2-switch__input + label {
  color: #3bb237; }

.o2-switch.o2-switch--success .o2-switch__input + label::before {
  background-color: #3bb237; }

.o2-switch.o2-switch--success .o2-switch__input:checked + label::after {
  background: white; }

.o2-switch.o2-switch--success .o2-switch__input:checked + label::before {
  background-color: #3bb237; }

.o2-switch.o2-switch--success .o2-switch__input:not(:checked) + label::after {
  background: white; }

.o2-switch.o2-switch--success .o2-switch__input:not(:checked) + label::before {
  background: #dddddd; }

/* warning switch */
.o2-switch.o2-switch--warning .o2-switch__input + label {
  color: #ff8b2f; }

.o2-switch.o2-switch--warning .o2-switch__input:checked + label::after {
  background: white; }

.o2-switch.o2-switch--warning .o2-switch__input:checked + label::before {
  background-color: #ff8b2f; }

.o2-switch.o2-switch--warning .o2-switch__input:not(:checked) + label::after {
  background: white;
  border: 1px solid #ff8b2f; }

.o2-switch.o2-switch--warning .o2-switch__input:not(:checked) + label::before {
  border: 1px solid #ff8b2f; }

/* danger switch */
.o2-switch.o2-switch--danger .o2-switch__input + label {
  color: #e82b3b; }

.o2-switch.o2-switch--danger .o2-switch__input:checked + label::before {
  background-color: #e82b3b; }

/* empty switch */
.o2-switch.o2-switch--empty label {
  color: #393939;
  padding: 0 0 0 36px; }

.alert {
  position: relative;
  padding: 1.35rem 3rem 1.35rem 1.2rem;
  margin-bottom: 12px;
  border: 0px;
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
  transform-origin: top center;
  transition-property: transform, opacity;
  transition-duration: 600ms, 300ms;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }
  .alert.alert--closing {
    opacity: 0;
    transform: scaleY(0); }
    .alert.alert--closing h4, .alert.alert--closing ul, .alert.alert--closing ol, .alert.alert--closing p, .alert.alert--closing .btn, .alert.alert--closing::before, .alert.alert--closing .close {
      opacity: 0;
      transition-property: opacity;
      transition-duration: 600ms;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      transition-timing-function: var(--echt-transition); }
  .alert {
    color: white; }
  .alert::before {
    display: table-cell;
    float: left;
    margin-right: 12px;
    position: relative;
    font-family: 'o2font' !important;
    font-size: 20px;
    line-height: 1;
    color: white;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 3px;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition); }
    @media (max-width: 480px) {
      .alert::before {
        font-size: 20px;
        margin-right: 8px;
        padding-top: 0px; } }
  .alert > .close {
    position: absolute;
    top: 11px;
    right: 12px;
    opacity: .6;
    font-size: 27px;
    font-weight: normal;
    text-shadow: none;
    color: white;
    transition: opacity 0.1s ease-in; }
    .alert > .close:hover {
      opacity: 1;
      color: white;
      transition: opacity 0.2s ease-out; }

.alert-content {
  display: table-cell;
  text-shadow: none; }
  .alert-content > h4,
  .alert-content > ol li,
  .alert-content > p {
    color: white;
    margin-bottom: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition); }
  .alert-content > h4 {
    margin-top: -4px; }
  .alert-content > ul {
    margin-top: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition); }
    .alert-content > ul > li {
      background-image: none;
      padding: 0;
      color: white; }
      .alert-content > ul > li:before {
        color: white; }
      .alert-content > ul > li > p, .alert-content > ul > li > span {
        color: white; }
  .alert-content > ol {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 0 16px;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition); }
    .alert-content > ol li > p, .alert-content > ol li > span {
      color: white; }
  .alert-content a:not(.btn) {
    color: white;
    text-decoration: underline;
    font-weight: 600; }
  .alert-content > .btn {
    margin-top: 10px;
    color: white;
    background: none;
    margin-right: 12px;
    margin-bottom: 2px;
    text-shadow: none;
    min-width: 60px;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition); }
    .alert-content > .btn.btn-white {
      color: #0093d5;
      background: white;
      border: 1px solid #d9d9d9; }
      .alert-content > .btn.btn-white:hover {
        background: #F2F2F2 !important; }
    .alert-content > .btn.btn-link {
      text-decoration: underline;
      transition: none; }
    .alert-content > .btn:not(.btn-link) {
      border: 1px solid white;
      transition: background-color 0.1s ease-in; }
    .alert-content > .btn:hover:not(.btn-link) {
      background: rgba(255, 255, 255, 0.4); }
    .alert-content > .btn:active:not(.btn-link) {
      background: rgba(255, 255, 255, 0.3); }
    .alert-content > .btn.btn-link:hover, .alert-content > .btn.btn-link:focus, .alert-content > .btn.btn-link:hover:before, .alert-content > .btn.btn-link:focus:before, .alert-content > .btn.btn-link:before {
      color: white;
      background: transparent;
      transition: none;
      transform: none; }

.alert .btn-group {
  border-radius: 2px; }
  .alert .btn-group .btn {
    margin-top: 0;
    margin-bottom: 0;
    border: 0 !important; }
  .alert .btn-group .dropdown-menu {
    padding: 0; }
  .alert .btn-group ul li a {
    color: #3b4653; }

.alert-info {
  background-color: #0093d5; }
  .alert-info:before {
    content: '\e9bc'; }

.alert-success {
  background-color: #3bb237; }
  .alert-success:before {
    content: '\e9ac'; }

.alert-warning {
  background-color: #ff8b2f; }
  .alert-warning:before {
    content: '\e9ba'; }

.alert-danger,
.alert-error {
  background-color: #e82b3b; }
  .alert-danger:before,
  .alert-error:before {
    content: '\e9a9'; }

.alert.alert-inline-button {
  padding: 0.8rem 1.2rem; }
  .alert.alert-inline-button::before {
    margin-top: 5px; }
    @media (max-width: 480px) {
      .alert.alert-inline-button::before {
        margin-top: 0px; } }
  .alert.alert-inline-button > .alert-content {
    padding: 0px; }
    @media (min-width: 768px) {
      .alert.alert-inline-button > .alert-content {
        display: flex;
        align-items: center; } }
    .alert.alert-inline-button > .alert-content > p {
      margin-right: 12px; }
      @media (max-width: 767px) {
        .alert.alert-inline-button > .alert-content > p {
          margin: 0px; } }
      .alert.alert-inline-button > .alert-content > p > span.h5 {
        color: white;
        font-weight: 600; }
    .alert.alert-inline-button > .alert-content .label-small {
      color: rgba(255, 255, 255, 0.8);
      display: block;
      line-height: 1.4;
      margin-top: 0px;
      font-size: 14px; }
    .alert.alert-inline-button > .alert-content > .btn {
      margin: 0px; }
      @media (min-width: 768px) {
        .alert.alert-inline-button > .alert-content > .btn.btn-xs {
          font-size: 13px;
          padding: 5px 12px; } }
      @media (max-width: 767px) {
        .alert.alert-inline-button > .alert-content > .btn.btn-xs {
          margin: 6px 0px 3px 0px; } }

.alert.popup-alert-notice {
  background-color: #ff8b2f;
  color: #3b4653; }
  .alert.popup-alert-notice .popup-alert-close {
    color: #3b4653;
    margin-top: 0;
    margin-bottom: 0;
    padding: 4px 12px; }

.alert.promocode {
  color: inherit;
  padding-right: 35px; }
  .alert.promocode.alert-success {
    background-color: #effaee;
    border-color: #3bb237; }
    .alert.promocode.alert-success:before {
      color: #3bb237; }
  .alert.promocode.alert-warning {
    background-color: #fffdfb;
    border-color: #ff8b2f; }
    .alert.promocode.alert-warning:before {
      color: #ff8b2f; }
  .alert.promocode.alert-danger, .alert.promocode.alert-error {
    background-color: #f9ccd0;
    border-color: #e82b3b; }
    .alert.promocode.alert-danger:before, .alert.promocode.alert-error:before {
      color: #e82b3b; }
  .alert.promocode.alert-info {
    background-color: #56caff;
    border-color: #0093d5; }
    .alert.promocode.alert-info:before {
      color: #0093d5; }
  .alert.promocode .alert-content {
    display: grid;
    -webkit-display: grid;
    -ms-display: grid;
    -o-display: grid;
    -moz-display: grid; }
    .alert.promocode .alert-content p {
      color: inherit; }
      .alert.promocode .alert-content p.promocode__alert-text {
        margin-bottom: 12px; }
    .alert.promocode .alert-content a:not(.btn) {
      color: #0093d5;
      text-decoration: none; }
      .alert.promocode .alert-content a:not(.btn):hover {
        text-decoration: underline;
        color: #2485c4; }
  .alert.promocode .close {
    color: inherit; }

[data-o2-slide-to] {
  cursor: pointer; }

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes flickerAnimation {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

.animate-flicker {
  -webkit-animation: flickerAnimation 1.5s infinite;
          animation: flickerAnimation 1.5s infinite; }

.dropdown-box {
  border-radius: 2px;
  margin-bottom: 12px;
  min-height: 60px;
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 0.2s; }
  .dropdown-box:hover {
    box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.2); }
  .dropdown-box .dropdown-title {
    position: relative;
    padding: 17px 42px 18px 12px;
    border-radius: 2px;
    cursor: pointer; }
    @media screen and (min-width: 1200px) {
      .dropdown-box .dropdown-title {
        padding: 17px 54px 18px 24px; } }
    .dropdown-box .dropdown-title .title {
      font-size: 18px;
      color: #343434;
      line-height: 1.4;
      text-decoration: none;
      transition: 0.2s ease;
      font-weight: 400; }
    .dropdown-box .dropdown-title:hover .title, .dropdown-box .dropdown-title.opened .title {
      color: #343434;
      transition: 0.2s ease; }
    .dropdown-box .dropdown-title a i {
      margin-right: 10px; }
    .dropdown-box .dropdown-title.opened + .box-content {
      visibility: visible;
      opacity: 1;
      max-height: 100%;
      transition: all 0.25s ease-in-out;
      padding-top: 12px;
      padding-bottom: 12px; }
  .dropdown-box .box-content {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.15s ease-out;
    padding-left: 12px;
    padding-right: 12px;
    background: white;
    border-radius: 2px; }
    @media screen and (min-width: 1200px) {
      .dropdown-box .box-content {
        padding-left: 24px;
        padding-right: 24px; } }
    .dropdown-box .box-content .item-detail {
      position: relative;
      padding-left: 0;
      margin-bottom: 0; }
    .dropdown-box .box-content .item-detail h5 {
      margin: 0 0 5px; }
    .dropdown-box .box-content .item-detail > i {
      font-size: 30px;
      left: 0;
      position: absolute; }
  .dropdown-box:not(.dropdown-no-border) .dropdown-title:not(.opened):hover .drop-btn {
    -webkit-animation-name: bounce-arrow;
            animation-name: bounce-arrow;
    -moz-animation-name: bounce-arrow; }
  .dropdown-box.dropdown-no-border {
    box-shadow: none;
    border: 0;
    min-height: auto;
    margin-bottom: 0; }
    .dropdown-box.dropdown-no-border .dropdown-title {
      padding: 6px 0; }
      .dropdown-box.dropdown-no-border .dropdown-title .title {
        color: #343434;
        font-size: 14px;
        line-height: 14px; }
      .dropdown-box.dropdown-no-border .dropdown-title .drop-btn {
        font-size: 18px;
        color: #343434;
        font-weight: bold;
        top: 9px;
        right: -2px;
        width: 18px;
        height: 18px; }
      .dropdown-box.dropdown-no-border .dropdown-title.opened + .box-content {
        overflow: auto; }
    .dropdown-box.dropdown-no-border .dropdown-title, .dropdown-box.dropdown-no-border .dropdown-title:hover, .dropdown-box.dropdown-no-border .dropdown-title.opened {
      background: transparent; }
    .dropdown-box.dropdown-no-border .box-content {
      overflow: hidden; }
    .dropdown-box.dropdown-no-border + .dropdown-no-border .dropdown-title {
      border-top: 1px solid #d9dbe2; }

.drop-btn {
  font-family: 'o2font' !important;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #0093d5;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  position: absolute;
  width: 28px;
  top: 16px;
  height: 28px;
  right: 7px;
  transition: all 0.2s ease; }
  @media screen and (min-width: 1200px) {
    .drop-btn {
      right: 19px; } }
  .drop-btn:before {
    content: "\e99f";
    top: 3px;
    position: relative; }
  .drop-btn.opened {
    transform: rotate(180deg);
    transform-origin: 50% 50%; }

/* Dropdown box additional button */
.dropdown-box .dropdown-title .action-icon {
  position: absolute;
  right: 42px;
  top: 1px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center; }

.dropdown-box .dropdown-title .action-icon a {
  display: block;
  font-size: 16px; }

.dropdown-box .dropdown-title .action-icon a:hover {
  text-decoration: none; }

.dropdown-box .dropdown-title .action-icon a i {
  margin-right: 0; }

/* DROPDOWN BOX TABLE */
.dropdown-box.dropdown-box-table {
  border-color: #dcdcdc;
  border-radius: 0;
  border-width: 1px 0px 0px 0px;
  margin-bottom: 0; }

.dropdown-box.dropdown-box-table .title {
  margin-right: 30px; }

.dropdown-box.dropdown-box-table .box-content,
.dropdown-box.dropdown-box-table .dropdown-title {
  border-radius: 0; }

@media (max-width: 767px) {
  .dropdown-box.dropdown-box-table .title + span {
    display: block; }
  .dropdown-box.dropdown-box-table .dropdown-title .description {
    margin: 10px 0 0 0; }
  .dropdown-box .box-content .item-detail {
    padding-left: 0; } }

@media (min-width: 768px) {
  .dropdown-box.dropdown-box-table .dropdown-title .description {
    margin: 10px 0 0 146px; } }

.inline-dropdown {
  display: inline-block;
  position: relative;
  color: #0093d5;
  cursor: pointer; }

.inline-dropdown:hover {
  text-decoration: none; }

.inline-dropdown:hover .dropdown-menu {
  display: block; }

.inline-dropdown .dropdown-menu {
  top: 100%;
  margin: 0;
  min-width: 120px; }

.inline-dropdown li {
  border-bottom: none; }

.inline-dropdown li a {
  background-color: white;
  padding: 4px 12px;
  text-align: right; }

.inline-dropdown li a:hover {
  background-color: #c0c0c0; }

.dropdown-text-box {
  position: relative;
  margin-bottom: 12px; }

.dropdown-text-box .dropdown-text-box {
  margin-bottom: 6px; }

.dropdown-text-box > .text-box-content {
  display: none;
  padding: 12px 0px; }

.dropdown-text-box > a {
  display: inline-block; }

.dropdown-text-box.dropdown-icon-after > a {
  display: block; }

.dropdown-text-box > a::after {
  pointer-events: all;
  cursor: pointer; }

.dropdown-text-box > a::after {
  pointer-events: all;
  cursor: pointer; }

.dropdown-text-box > a > h4 {
  margin-bottom: 3px; }

.dropdown-text-box.dropdown-icon-after > a > h4 {
  margin-right: 18px; }

.dropdown-text-box.dropdown-text-title > a,
.dropdown-text-box.dropdown-text-title > a:hover {
  text-decoration: none; }

.dropdown-text-box > a > h5,
.dropdown-text-box > a > h6 {
  margin-bottom: 0px; }

.dropdown-text-box > a > h4
.dropdown-text-box > a > h5,
.dropdown-text-box > a > h6 {
  display: inline; }

.dropdown-text-box.dropdown-icon-after > a > h5,
.dropdown-text-box.dropdown-icon-after > a > h6 {
  margin-right: 15px;
  font-size: 15px;
  font-weight: 400; }

.dropdown-text-box.dropdown-icon-before > a::before {
  content: '\e99f';
  display: inline-block;
  margin-right: 6px;
  position: relative;
  font-family: "o2font" !important;
  font-size: 14px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  color: #2485c4;
  /* Firefox */
  /* WebKit */
  /* Opera */
  /* Standard */
  transition: all 0.4s ease; }

.dropdown-text-box.expanded.dropdown-icon-before > a::before {
  transform: rotate(-180deg);
  transform-origin: 50% 50%; }

.dropdown-text-box.dropdown-icon-after > a::after {
  content: '\e99f';
  display: inline-block;
  position: absolute;
  right: 0px;
  top: -3px;
  font-family: "o2font" !important;
  font-size: 14px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  color: #2485c4;
  /* Firefox */
  /* WebKit */
  /* Opera */
  /* Standard */
  transition: all 0.4s ease; }

.dropdown-text-box.dropdown-text-title.dropdown-icon-after > a::after {
  font-size: 18px;
  font-weight: bold;
  top: -4px;
  color: #242424; }

.dropdown-text-box.dropdown-text-sub-title.dropdown-icon-after > a::after {
  top: -3px;
  color: #242424;
  margin-right: 12px; }

.dropdown-text-box.dropdown-text-sub-title.dropdown-icon-after > a > h5,
.dropdown-text-box.dropdown-text-sub-title.dropdown-icon-after > a > h6 {
  margin-right: 28px; }

.dropdown-text-box.expanded.dropdown-icon-after > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

/* BUTTONS */
.btn {
  min-width: 70px;
  padding: 0.7rem 2rem;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
  margin-bottom: 4px;
  white-space: normal;
  border-radius: 2px; }

.btn,
.btn:hover,
.btn:focus,
.btn:active {
  box-shadow: none; }

.btn:focus {
  outline: none; }

.btn.btn-lg {
  padding: 1.3rem 3rem 1.4rem;
  font-size: 1.5rem;
  border-radius: 2px; }

.btn.btn-xs {
  padding: 0.4rem 1.2rem 0.5rem;
  font-size: 1.4rem;
  border-radius: 2px; }

.btn-toggle {
  min-width: 29px; }

.btn-default {
  border-color: #d9dbe2;
  background: none; }

.btn-default .glyphicon {
  color: #5c5c5c; }

.btn-default:hover,
.btn-default:hover:focus,
.btn-default:active:hover, .btn-default.active:hover, .open > .dropdown-toggle.btn-default:hover, .btn-default:active:focus, .btn-default.active:focus, .open > .dropdown-toggle.btn-default:focus, .btn-default:active.focus, .btn-default.active.focus, .open > .dropdown-toggle.btn-default.focus,
.btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: inherit;
  background: none;
  border-color: #d9dbe2; }

.btn-default:focus, .btn-default:active, .btn-default.active, .open > .dropdown-toggle.btn-default {
  color: inherit;
  background: none;
  border-color: #d9dbe2; }

.btn-default.disabled:hover, .btn-default[disabled]:hover, fieldset[disabled] .btn-default:hover, .btn-default.disabled:focus, .btn-default[disabled]:focus, fieldset[disabled] .btn-default:focus, .btn-default.disabled.focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default.focus {
  background: none;
  border-color: #d9dbe2; }

.btn-default.disabled ~ .control-label, .btn-default[disabled] ~ .control-label {
  opacity: 0.65; }

/* BUTTONS > Button blue */
.btn-blue,
input[type="button"].btn-blue,
input[type="submit"].btn-blue,
input[type="reset"].btn-blue {
  background: none repeat scroll 0 0 #0093d5;
  color: white;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

input[type="button"].btn-blue.btn-disabled,
input[type="button"].btn-blue[disabled],
input[type="button"].btn-blue.disabled,
input[type="submit"].btn-blue.disabled,
input[type="submit"].btn-blue[disabled],
input[type="reset"].btn-blue.btn-disabled,
input[type="reset"].btn-blue[disabled],
input[type="button"].btn-blue.btn-disabled:hover,
input[type="button"].btn-blue[disabled]:hover,
input[type="button"].btn-blue.disabled:hover,
input[type="submit"].btn-blue.disabled:hover,
input[type="submit"].btn-blue[disabled]:hover,
input[type="reset"].btn-blue.btn-disabled:hover,
input[type="reset"].btn-blue[disabled]:hover {
  background-color: rgba(0, 147, 213, 0.7);
  color: white; }

.btn-blue:hover,
input[type="button"].btn-blue:hover,
input[type="submit"].btn-blue:hover,
input[type="reset"].btn-blue:hover,
.btn-blue.hover,
.btn-blue:focus,
.open .dropdown-toggle.btn-blue {
  background: none repeat scroll 0 0 #0070a2;
  color: white; }

.btn-blue:active,
.btn-blue.active,
input[type="button"].btn-blue:active,
input[type="submit"].btn-blue:active,
input[type="reset"].btn-blue:active {
  background: #0093d5;
  color: white; }

.btn-blue.btn-disabled,
.btn-blue[disabled],
fieldset[disabled] .btn-blue,
.btn-blue.btn-disabled:hover,
.btn-blue[disabled]:hover,
fieldset[disabled] .btn-blue:hover,
.btn-blue.btn-disabled:focus,
.btn-blue[disabled]:focus,
fieldset[disabled] .btn-blue:focus,
.btn-blue.btn-disabled:active,
.btn-blue[disabled]:active,
fieldset[disabled] .btn-blue:active,
.btn-blue.btn-disabled.active,
.btn-blue[disabled].active,
fieldset[disabled] .btn-blue.active {
  background-color: rgba(0, 147, 213, 0.7);
  color: white; }

/* BUTTONS > Button white */
.btn-white,
input[type="button"].btn-white,
input[type="submit"].btn-white,
input[type="reset"].btn-white {
  background: none repeat scroll 0 0 white;
  border: 1px solid #0093d5;
  color: #0093d5;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

.btn.btn-white:before {
  background-color: rgba(115, 176, 206, 0.1); }

.btn-white:hover,
input[type="button"].btn-white:hover,
input[type="submit"].btn-white:hover,
input[type="reset"].btn-white:hover,
.btn-white.hover,
.btn-white:focus,
input[type="button"].btn-white:hover,
input[type="submit"].btn-white:hover,
input[type="reset"].btn-white:hover {
  color: #0093d5;
  background: none repeat scroll 0 0 #e4f7ff;
  border-color: #0093d5;
  box-shadow: none; }

.btn-white:active,
input[type="button"].btn-white:active,
input[type="submit"].btn-white:active,
input[type="reset"].btn-white:active
.open .dropdown-toggle.btn-white {
  background-image: none;
  background: white;
  color: #0093d5; }

.btn-white.btn-disabled,
.btn-white[disabled],
fieldset[disabled] .btn-white,
.btn-white.btn-disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white:hover,
.btn-white.btn-disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled] .btn-white:focus,
.btn-white.btn-disabled:active,
.btn-white[disabled]:active,
fieldset[disabled] .btn-white:active,
.btn-white.btn-disabled.active,
.btn-white[disabled].active,
fieldset[disabled] .btn-white.active {
  background-color: white;
  border-color: rgba(0, 147, 213, 0.7);
  color: rgba(0, 147, 213, 0.7); }

/* BUTTONS -> Button red */
.btn-red,
input[type="button"].btn-red,
input[type="submit"].btn-red,
input[type="reset"].btn-red {
  background: none repeat scroll 0 0 #e82b3b;
  color: white;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

.btn-red:hover,
.btn-red.hover,
.btn-red:focus,
input[type="button"].btn-red:hover,
input[type="submit"].btn-red:hover,
input[type="reset"].btn-red:hover,
input[type="button"].btn-red:hover,
input[type="submit"].btn-red:hover,
input[type="reset"].btn-red:hover,
.open .dropdown-toggle.btn-red {
  color: white;
  background: none repeat scroll 0 0 #ca1625; }

.btn-red:active,
.btn-red.active,
input[type="button"].btn-red:active,
input[type="submit"].btn-red:active,
input[type="reset"].btn-red:active {
  background-image: none;
  color: white;
  background: #e82b3b; }

.btn-red.btn-disabled,
.btn-red[disabled],
fieldset[disabled] .btn-red,
.btn-red.btn-disabled:hover,
.btn-red[disabled]:hover,
fieldset[disabled] .btn-red:hover,
.btn-red.btn-disabled:focus,
.btn-red[disabled]:focus,
fieldset[disabled] .btn-red:focus,
.btn-red.btn-disabled:active,
.btn-red[disabled]:active,
fieldset[disabled] .btn-red:active,
.btn-red.btn-disabled.active,
.btn-red[disabled].active,
fieldset[disabled] .btn-red.active {
  background-color: rgba(232, 43, 59, 0.7);
  color: white; }

/* BUTTONS -> Button green */
.btn-green,
input[type="button"].btn-green,
input[type="submit"].btn-green,
input[type="reset"].btn-green {
  background: none repeat scroll 0 0 #00b9b5;
  color: white;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

.btn-green:hover,
.btn-green.hover,
.btn-green:focus,
input[type="button"].btn-green:hover,
input[type="submit"].btn-green:hover,
input[type="reset"].btn-green:hover,
input[type="button"].btn-green:hover,
input[type="submit"].btn-green:hover,
input[type="reset"].btn-green:hover,
.open .dropdown-toggle.btn-green {
  color: white;
  background: none repeat scroll 0 0 #008683; }

.btn-green:active,
.btn-green.active,
input[type="button"].btn-green:active,
input[type="submit"].btn-green:active,
input[type="reset"].btn-green:active {
  background-image: none;
  color: white;
  background: #00b9b5; }

.btn-green.btn-disabled,
.btn-green[disabled],
fieldset[disabled] .btn-green,
.btn-green.btn-disabled:hover,
.btn-green[disabled]:hover,
fieldset[disabled] .btn-green:hover,
.btn-green.btn-disabled:focus,
.btn-green[disabled]:focus,
fieldset[disabled] .btn-green:focus,
.btn-green.btn-disabled:active,
.btn-green[disabled]:active,
fieldset[disabled] .btn-green:active,
.btn-green.btn-disabled.active,
.btn-green[disabled].active,
fieldset[disabled] .btn-green.active {
  background-color: rgba(0, 185, 181, 0.7);
  color: white; }

/* BUTTONS -> Button inverse */
.btn-inverse,
input[type="button"].btn-inverse,
input[type="submit"].btn-inverse,
input[type="reset"].btn-inverse {
  background: none;
  border: 1px solid white;
  color: white;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

.btn-inverse:hover,
.btn-inverse.hover,
.btn-inverse:focus,
input[type="button"].btn-inverse:hover,
input[type="submit"].btn-inverse:hover,
input[type="reset"].btn-inverse:hover,
input[type="button"].btn-inverse:hover,
input[type="submit"].btn-inverse:hover,
input[type="reset"].btn-inverse:hover {
  color: #000066;
  background: white;
  border-color: white; }

.btn-inverse:active,
.btn-inverse.active,
input[type="button"].btn-inverse:active,
input[type="submit"].btn-inverse:active,
input[type="reset"].btn-inverse:active,
.open .dropdown-toggle.btn-inverse {
  background: none;
  border-color: #ccd0d8;
  color: white; }

.btn-inverse.btn-disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.btn-disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.btn-disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.btn-disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.btn-disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  color: #ccd0d8;
  border-color: #ccd0d8;
  background: transparent; }

.btn-link {
  color: #0093d5;
  font-weight: 600;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

.btn-link:hover,
.btn-link:focus {
  color: #0070a2;
  text-decoration: none; }

.btn-link:active {
  color: #0093d5; }

.open .dropdown-toggle.btn-red {
  border-left: 1px solid black; }

.btn-disabled,
.btn-disabled:hover,
.btn-disabled:active,
.btn-disabled:focus {
  color: #f2f3f5;
  cursor: not-allowed; }

.btn-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-right {
  margin-left: -3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-fb,
input[type="button"].btn-fb,
input[type="submit"].btn-fb,
input[type="reset"].btn-fb {
  width: 150px;
  border: 1px solid #304886;
  background: #3f5d9c url("../images/eportal/fb-logo.png") no-repeat scroll 10px 6px; }

.btn-fb:hover,
.btn-fb.hover,
.btn-fb:focus,
input[type="button"].btn-fb:hover,
input[type="submit"].btn-fb:hover,
input[type="reset"].btn-fb:hover,
input[type="button"].btn-fb:hover,
input[type="submit"].btn-fb:hover,
input[type="reset"].btn-fb:hover {
  border: 1px solid #34599b;
  background: #4a67b1 url("../images/eportal/fb-logo.png") no-repeat scroll 10px 6px;
  box-shadow: none;
  color: white; }

.btn-group:not(.bootstrap-select) {
  display: inline-flex; }

.btn-group > .btn + .dropdown-toggle {
  padding-left: 16px;
  padding-right: 16px;
  min-width: 40px; }

.btn-group > .btn + .dropdown-toggle {
  border-left: 1px solid rgba(255, 255, 255, 0.2); }

.btn-group > .btn.btn-white + .dropdown-toggle {
  border-left: 1px solid #0093d5; }

.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu {
  border: none;
  padding: 0;
  min-width: 220px;
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
  border-width: 0;
  display: block;
  opacity: 0;
  max-height: 0;
  transition: max-height 300ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: max-height 300ms var(--echt-transition), opacity 300ms var(--echt-transition); }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li > a {
    opacity: 0;
    transform: translateX(30px);
    transition-: 0.5s;
    transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1), background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1), color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: transform 300ms var(--echt-transition), opacity 300ms var(--echt-transition), background-color 200ms var(--echt-transition), color 200ms var(--echt-transition); }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(1) > a {
    transition-: 40ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(2) > a {
    transition-: 80ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(3) > a {
    transition-: 120ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(4) > a {
    transition-: 160ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(5) > a {
    transition-: 200ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(6) > a {
    transition-: 240ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(7) > a {
    transition-: 280ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(8) > a {
    transition-: 320ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(9) > a {
    transition-: 360ms; }
  .btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:nth-child(10) > a {
    transition-: 400ms; }

.btn-group.open > .btn + .dropdown-toggle + .dropdown-menu {
  visibility: visible;
  opacity: 1;
  max-height: 100vh; }
  .btn-group.open > .btn + .dropdown-toggle + .dropdown-menu li > a {
    opacity: 1;
    transform: translateX(0px); }

.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li {
  border-bottom: 1px solid rgba(82, 109, 119, 0.1); }

.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:first-child,
.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:first-child a:hover {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:last-child {
  border-bottom: none; }

.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:last-child,
.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu li:last-child a:hover {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px; }

.btn-group > .btn + .dropdown-toggle ~ .dropdown-menu > li > a {
  color: #0093d5; }

.btn-group > .btn-blue ~ .dropdown-menu {
  background: white;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition); }

.btn-group > .btn-blue + .dropdown-toggle ~ .dropdown-menu > li > a:hover,
.btn-group > .btn-blue + .dropdown-toggle ~ .dropdown-menu > li > a:focus {
  background: #e4f7ff; }

.btn-group > .btn-white + .dropdown-toggle ~ .dropdown-menu > li > a {
  color: #0093d5; }

.btn-group > .btn-white ~ .dropdown-menu {
  background: white; }

.btn-group > .btn-white + .dropdown-toggle ~ .dropdown-menu > li > a:hover,
.btn-group > .btn-white + .dropdown-toggle ~ .dropdown-menu > li > a:focus {
  background: #e4f7ff; }

.btn-group > .btn-red ~ .dropdown-menu {
  background: white; }

.btn-group > .btn-red + .dropdown-toggle ~ .dropdown-menu > li > a:hover,
.btn-group > .btn-red + .dropdown-toggle ~ .dropdown-menu > li > a:focus {
  background: #e4f7ff; }

.btn-group > .btn-green ~ .dropdown-menu {
  background: white; }

.btn-group > .btn-green + .dropdown-toggle ~ .dropdown-menu > li > a:hover,
.btn-group > .btn-green + .dropdown-toggle ~ .dropdown-menu > li > a:focus {
  background: #e4f7ff; }

.btn-group > .btn-toggle ~ .dropdown-menu li > a {
  transition: 0.2s ease-in-out; }

/*Add remove items component*/
.add-remove-item > .btn {
  min-width: 40px;
  width: 40px;
  height: 40px;
  padding: 11px 0px;
  line-height: 1; }

.add-remove-item > .btn-left {
  position: relative;
  left: 2px; }

.add-remove-item > .btn [class^="o2f-"] {
  font-size: 20px;
  color: inherit; }

.add-remove-item > .btn.btn-xs {
  min-width: 34px;
  width: 34px;
  height: 34px;
  padding: 8px 0; }

.add-remove-item > .btn.btn-lg {
  min-width: 54px;
  width: 54px;
  height: 54px;
  padding: 13px 0; }

/*button spinner loader - BEGIN*/
.btn-spinner {
  position: relative;
  right: -10px;
  top: 2px;
  font-size: 16px; }

.btn-white .btn-spinner, .btn-link .btn-spinner {
  color: #0093d5; }

.btn-blue .btn-spinner, .btn-red .btn-spinner, .btn-green .btn-spinner {
  color: white; }

.btn-xs .btn-spinner {
  font-size: 14px; }

/*button spinner loader - END*/
.btn-group .btn [class^="o2f-"] {
  font-weight: bold; }

.btn-group .btn-blue [class^="o2f-"],
.btn-group .btn-red [class^="o2f-"],
.btn-group .btn-green [class^="o2f-"] {
  color: white; }

.btn-group .btn-white [class^="o2f-"] {
  color: #0093d5; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

/* button animations */
.btn.btn-animation:hover [class^="o2f-"] {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.btn.btn-animation [class^="o2f-"] {
  display: inline-flex;
  color: white;
  -webkit-animation-iteration-count: 0;
          animation-iteration-count: 0; }

.btn.btn-animation.btn-link [class^="o2f-"] {
  color: #0093d5; }

.o2fa {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
          animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-animation-timing-function: var(--echt-transition);
          animation-timing-function: var(--echt-transition); }

.o2fa--move-right {
  -webkit-animation-name: o2fa--move-right;
          animation-name: o2fa--move-right;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  margin-left: 20px; }

.o2fa--rotate {
  -webkit-animation-name: o2fa--rotate;
          animation-name: o2fa--rotate;
  -webkit-animation-iteration-count: infinite !important;
          animation-iteration-count: infinite !important;
  -webkit-animation-duration: 1350ms;
          animation-duration: 1350ms;
  margin-left: 13px;
  font-size: 15px; }

@-webkit-keyframes o2fa--rotate {
  0% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes o2fa--rotate {
  0% {
    transform: rotate(0deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

.progressbar {
  /* Reset the default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Get rid of default border in Firefox. */
  border: none;
  /* For IE */
  color: #0093d5;
  /* Dimensions */
  width: 100%;
  height: 5px;
  background-color: #e9f4fa;
  border-radius: 2px; }

/* default color right side */
.progressbar::-webkit-progress-bar {
  background-color: #e9f4fa;
  border-radius: 2px; }

/* default color left side */
.progressbar::-webkit-progress-value {
  background-color: #0093d5;
  border-radius: 2px; }

/* default color mozila left side */
.progressbar::-moz-progress-bar {
  background-color: #0093d5;
  border-radius: 2px; }

.progressbar.progressbar__value--yellow::-webkit-progress-value {
  background-color: #ff8b2f; }

.progressbar.progressbar__value--yellow::-moz-progress-bar {
  background-color: #ff8b2f; }

/* IE */
.progressbar.progressbar__value--yellow {
  color: #ff8b2f; }

.progressbar.progressbar__value--yellow::-ms-fill {
  background-color: #ff8b2f; }

.progressbar.progressbar__value--cyan::-webkit-progress-value {
  background-color: #00b9b5; }

.progressbar.progressbar__value--cyan::-moz-progress-bar {
  background-color: #00b9b5; }

/* IE */
.progressbar.progressbar__value--cyan {
  color: #00b9b5; }

.progressbar.progressbar__value--cyan::-ms-fill {
  background-color: #00b9b5; }

.progressbar.progressbar__value--success::-webkit-progress-value {
  background-color: #3bb237; }

.progressbar.progressbar__value--success::-moz-progress-bar {
  background-color: #3bb237; }

/* IE */
.progressbar.progressbar__value--success {
  color: #3bb237; }

.progressbar.progressbar__value--success::-ms-fill {
  background-color: #3bb237; }

.progressbar.progressbar__value--warning::-webkit-progress-value {
  background-color: #ff8b2f; }

.progressbar.progressbar__value--warning::-moz-progress-bar {
  background-color: #ff8b2f; }

/* IE */
.progressbar.progressbar__value--warning {
  color: #ff8b2f; }

.progressbar.progressbar__value--warning::-ms-fill {
  background-color: #ff8b2f; }

.progressbar.progressbar__value--danger::-webkit-progress-value,
.progressbar.progressbar__value--red::-webkit-progress-value {
  background-color: #e82b3b; }

.progressbar.progressbar__value--danger::-moz-progress-bar,
.progressbar.progressbar__value--red::-moz-progress-bar {
  background-color: #e82b3b; }

/* IE */
.progressbar.progressbar__value--danger,
.progressbar.progressbar__value--red {
  color: #e82b3b; }

.progressbar.progressbar__value--danger::-ms-fill,
.progressbar.progressbar__value--red::-ms-fill {
  background-color: #e82b3b; }

.progressbar-box {
  position: relative; }

.progressbar__title {
  text-align: center;
  font-size: 14px;
  color: #3b4653; }

.progressbar-box .progressbar__text {
  position: absolute;
  font-size: 12px;
  color: white;
  margin-top: 3px;
  margin-left: 10px; }

.progressbar__text-info {
  font-size: 12px;
  height: 23px;
  line-height: 23px;
  vertical-align: middle;
  color: #3b4653; }

.progressbar-box .progressbar__bullet {
  position: absolute;
  top: 8px;
  width: 13px;
  height: 13px;
  background-color: #0093d5;
  border-radius: 6.5px; }

.progressbar.progressbar--large {
  height: 23px; }

.progressbar.progressbar--half {
  width: 50%; }

/* Progressbar OLD */
.progress-bar {
  width: 100%;
  height: 12px;
  border-radius: 10px;
  background: #eee;
  box-shadow: none; }

.progress-bar .fill {
  border-radius: 10px;
  background: #0273d3;
  height: 12px;
  width: 0; }

.progress-bar-caption {
  position: relative;
  top: -3px;
  left: 16px;
  margin: 0; }

.progress-wrap {
  font-size: 13px;
  position: relative; }

.progress-wrap .progress-text {
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 600;
  margin-left: 14px; }

.progress-wrap .progress-bar {
  width: 90%;
  height: 25px;
  border-radius: 2px;
  background: #eee;
  box-shadow: none;
  margin: -2px 7px 12px 7px; }

.progress-wrap .progress-button {
  float: right;
  padding-right: 6px;
  margin-top: 20px; }

.progress-wrap .progress-button form {
  margin: 0px; }

.progress-wrap .progress-button .btn {
  float: right; }

.progress-wrap .progress-bar-wrap,
.progress-wrap .progress-legend,
.progress-wrap .progress-slow-extra-data {
  width: 80%; }

.progress-wrap .progress-bar .fill {
  border-radius: 2px;
  background: #9ecafb;
  height: 25px;
  width: 0;
  color: #3b4653;
  text-align: left;
  padding: 2px 10px;
  font-size: 13px;
  white-space: nowrap;
  overflow: visible !important; }

.progress-wrap.red {
  color: #343434 !important; }

.progress-wrap.red .progress-bar .fill {
  background: #f6dcdb; }

.progress-wrap.red .progress-bar .fill .days {
  color: #990000; }

.progress-bar .fill:first-of-type {
  float: left; }

.progress-bar-caption {
  position: relative;
  top: -3px;
  left: 16px;
  margin: 0; }

.progress-wrap {
  font-size: 13px;
  margin-bottom: 12px;
  position: relative; }

.progress-wrap .progress-text {
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 600;
  margin-left: 14px; }

.progress-wrap .progress-bar {
  width: 90%;
  height: 25px;
  border-radius: 2px;
  background: #eee;
  box-shadow: none;
  margin: -2px 7px 12px 7px; }

.progress-wrap .progress-bar-wrap,
.progress-wrap .progress-legend,
.progress-wrap .progress-slow-extra-data {
  width: 80%; }

.progress-wrap .progress-bar .fill {
  border-radius: 2px;
  height: 25px;
  width: 0;
  color: #3b4653;
  text-align: left;
  padding: 2px 10px;
  font-size: 13px;
  white-space: nowrap;
  overflow: visible !important; }

.progress-wrap.red {
  color: #343434 !important; }

.progress-wrap .red {
  background: #f6dcdb; }

.progress-wrap.red .progress-bar .fill .days {
  color: #990000; }

.progress-wrap .progress-legend {
  clear: both;
  margin-bottom: 12px; }

.progress-wrap .progress-legend .col-sm-10 {
  padding-left: 21px; }

.progress-wrap .progress-slow-extra-data .col-sm-10 {
  padding-left: 15px; }

.progress-wrap .progress-slow-extra-data .col-sm-10 form {
  margin: 0px auto; }

.progress-wrap .progress-slow-extra-data .col-sm-10 form input.btn.link {
  padding: 1px 6px; }

.progress-wrap .progress-legend .legend-square {
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 5px;
  background: #9ecafb; }

.progress-wrap .progress-legend.allowance-info .legend-square {
  padding-left: 3px;
  font-size: 14px;
  margin-top: -2px;
  background-color: transparent; }

.progress-wrap .dark-blue {
  background-color: #9ecafb; }

.progress-wrap .light-blue {
  background-color: #D2E9FF; }

.progress-wrap .progress-legend:after,
.progress-wrap:after {
  content: ' ';
  clear: both;
  display: block; }

/* TABS - Bootstrap override */
.nav > li > a {
  padding: 10px 5px; }

.nav-tabs > li > a {
  border-radius: 2px 2px 0 0;
  display: flex;
  justify-content: center;
  outline: 0;
  align-items: center;
  flex-direction: row; }

.nav-tabs > li > a:hover {
  border-color: transparent; }

.nav > li > a:hover, .nav > li > a:focus {
  background-color: #f7f7f9; }

/* TABS */
.nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) {
  border: none;
  margin-left: -6px;
  margin-right: -6px; }
  @media screen and (min-width: 1200px) {
    .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) {
      margin-left: -12px;
      margin-right: -12px; } }

.nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li {
  display: inline-block; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li a {
    transition: 0.15s ease;
    border-top: 0;
    border-bottom: 0;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    overflow: hidden; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.big > a {
    height: 80px;
    padding: 10px 12px; }
    .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.big > a > i {
      height: 25px;
      font-size: 30px;
      color: #0093d5; }
    .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.big > a > i + h2, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.big > a i + h3 {
      margin-left: 12px; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.big h2, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.big h3 {
    margin: 0;
    color: #0093d5;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.2px; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.small > a {
    padding: 6px;
    height: 60px; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.small h3, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.small h6 {
    color: #0093d5;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    letter-spacing: 0.2px; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li a:hover h2, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li a:hover h3, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li a:hover h6, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li a:hover i {
    color: #0070a2; }
  .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.active a h2, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.active a h3, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.active a h6, .nav-tabs:not(.nav-tabs-filter):not(.nm-tabs) > li.active a i {
    color: #343434; }

.nav-tabs > li a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  top: 0;
  background: #0093d5;
  height: 2px;
  transition-property: left, right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out; }

.nav-tabs > li:focus a:before, .nav-tabs > li:active a:before, .nav-tabs > li.active a:before {
  left: 0;
  right: 0; }

.nav-tabs > li > a {
  padding: 10px 0 18px 0;
  text-align: center;
  background: #f7f7f9; }

.nav-tabs > li a,
.nav-tabs > li a:hover {
  margin-right: 0; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background: white;
  border: solid 1px #e9f5ff;
  border-bottom: none;
  border-top: none; }

.nav-tabs > li.active.big > a {
  border-bottom: none;
  z-index: 10; }

.nav-tabs > li.active.small > a {
  border-bottom: none;
  padding: 5px 0;
  z-index: 10; }

.accordion-tabs .drop-btn.closed {
  display: none; }

.panel-group.visible-xs .panel-default {
  border: 0;
  box-shadow: none; }
  .panel-group.visible-xs .panel-default .panel-heading {
    padding: 0;
    border-radius: 0px; }
    .panel-group.visible-xs .panel-default .panel-heading .panel-title > a > i {
      height: 0;
      position: relative;
      display: flex;
      top: 18px;
      left: 7px;
      font-size: 18px;
      color: #0093d5; }
    .panel-group.visible-xs .panel-default .panel-heading .panel-title > a > i + h2, .panel-group.visible-xs .panel-default .panel-heading .panel-title > a > i + h3, .panel-group.visible-xs .panel-default .panel-heading .panel-title > a > i + h4, .panel-group.visible-xs .panel-default .panel-heading .panel-title > a > i + h5, .panel-group.visible-xs .panel-default .panel-heading .panel-title > a > i + h6 {
      padding-left: 40px; }
    .panel-group.visible-xs .panel-default .panel-heading .panel-title h2, .panel-group.visible-xs .panel-default .panel-heading .panel-title h3, .panel-group.visible-xs .panel-default .panel-heading .panel-title h4, .panel-group.visible-xs .panel-default .panel-heading .panel-title h5, .panel-group.visible-xs .panel-default .panel-heading .panel-title h6 {
      position: relative;
      font-size: 18px !important;
      margin: 0;
      padding: 12px;
      color: #0093d5; }
    .panel-group.visible-xs .panel-default .panel-heading .panel-title h2:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title h3:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title h4:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title h5:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title h6:after {
      content: "\e99d";
      font-family: o2font;
      transition: 0.15s ease;
      position: absolute;
      right: 8px; }
    .panel-group.visible-xs .panel-default .panel-heading .panel-title > a {
      text-decoration: none; }
    .panel-group.visible-xs .panel-default .panel-heading .panel-title .collapsed h2:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title .collapsed h3:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title .collapsed h4:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title .collapsed h5:after, .panel-group.visible-xs .panel-default .panel-heading .panel-title .collapsed h6:after {
      transition: 0.15s ease;
      transform: rotate(-180deg); }
  .panel-group.visible-xs .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: 0;
    padding: 15px;
    border: 1px solid #f5f5f5; }

.panel-group.visible-xs > .big .panel-title h2 {
  font-size: 18px; }

.panel-group.visible-xs > .small {
  font-size: 100%; }
  .panel-group.visible-xs > .small .panel-title h3 {
    font-size: 14px;
    margin-top: 10px; }

.panel-group.visible-xs .panel + .panel {
  margin-top: 2px; }

.nav-tabs.multitabs {
  display: inline-flex;
  width: 100%; }
  .nav-tabs.multitabs > li.multitab a {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 0; }
    .nav-tabs.multitabs > li.multitab a h3 {
      margin: 0; }
  .nav-tabs.multitabs > li.multitab:before, .nav-tabs.multitabs > li.multitab:hover:before {
    background: transparent; }
  .nav-tabs.multitabs > li.multitab:not(.active):hover a {
    color: #0070a2;
    background: #f7f7f9; }
  .nav-tabs.multitabs > li.multitab:not(.active):hover h3 {
    color: #0070a2; }
  .nav-tabs.multitabs > li.multitab.big {
    min-height: 80px; }
  .nav-tabs.multitabs > li.multitab.small {
    min-height: 60px; }

.tab-content {
  background: none;
  margin-bottom: 24px; }

.tab-content h2, .tab-content h3, .tab-content h4, .tab-content h5, .tab-content h6 {
  margin-top: 12px; }

.tab-content > div {
  padding: 2px 0;
  background: white; }

.nav-tabs.multitabs + .tab-content .nav-tabs.multitabs > li:not(.active) a {
  background-color: #e5f2fa; }
  .nav-tabs.multitabs + .tab-content .nav-tabs.multitabs > li:not(.active) a:hover {
    background-color: #e0f0f6; }

.tab-content.video > div {
  padding: 19px;
  background: white; }

/*Toggle tabs*/
.toggle-tabs {
  margin-bottom: 24px; }
  .toggle-tabs .nav-tabs > li > a {
    cursor: pointer !important; }
  .toggle-tabs .tab-content .tab-pane {
    display: none; }
  .toggle-tabs .nav-tabs > li.active.hidden-tab-content a {
    background: #f7f7f7; }
    .toggle-tabs .nav-tabs > li.active.hidden-tab-content a:before {
      left: 51%;
      right: 51%; }
    .toggle-tabs .nav-tabs > li.active.hidden-tab-content a h2 {
      color: #0093d5; }
    .toggle-tabs .nav-tabs > li.active.hidden-tab-content a:hover {
      background: #f7f7f9; }
      .toggle-tabs .nav-tabs > li.active.hidden-tab-content a:hover h2 {
        color: #0070a2; }

.nav.nav-tabs.nav-tabs-filter {
  text-align: left;
  border: 0; }

.nav-tabs-filter.nav-tabs > li {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #f2f3f5; }

.nav-tabs-filter > li.active {
  /*background: none repeat scroll 0 0 #f2f3f5;*/
  box-shadow: none;
  border-radius: 2px 2px 0 0;
  border: 1px solid #ccd0d8;
  border-bottom: none; }

.tab-content-filter {
  border: 1px solid #ccd0d8;
  border-radius: 2px;
  border-top-left-radius: 0;
  background: none repeat scroll 0 0 #f2f3f5; }

.tab-content-filter .bootstrap-select.btn-group .btn .filter-option {
  height: 25px; }

.tab-content-filter .btn-default {
  background-color: white; }

.form-horizontal.form-filter {
  margin-bottom: 0px;
  margin-top: 0; }

.nav.nav-tabs.nav-tabs-filter {
  text-align: left; }

.nav-tabs-filter.nav-tabs > li {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #f2f3f5;
  border: 1px solid #ccd0d8;
  border-bottom: 0;
  padding: 0; }

.nav-tabs-filter.nav-tabs > li > a {
  padding: 10px 12px; }

.nav-tabs-filter > li.active {
  /*background: none repeat scroll 0 0 #f2f3f5;*/
  box-shadow: none;
  border-radius: 2px 2px 0 0;
  border: 1px solid #ccd0d8;
  border-bottom: none; }

.tab-content-filter {
  border: 1px solid #ccd0d8;
  border-radius: 2px;
  border-top-left-radius: 0;
  background: none repeat scroll 0 0 #f7f7f9;
  padding: 12px;
  margin-bottom: 12px; }
  @media screen and (min-width: 1200px) {
    .tab-content-filter {
      padding: 24px; } }

.tab-content-filter .bootstrap-select.btn-group .btn .filter-option {
  height: 25px; }

.tab-content-filter .btn-default {
  background-color: white; }

.tab-content-filter .btn.settings {
  background: url(../images/icon-settings.png) no-repeat 0 16px;
  padding-left: 25px !important;
  padding-right: 10px !important;
  margin-top: -14px !important; }

.tab-content-filter .btn.close-settings {
  padding: 0 !important;
  min-width: 14px;
  background: url(../images/icon-gray-cross.png) no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  margin-top: -10px !important; }

.tab-content-filter .radio-inline {
  padding-left: 0px; }

.tab-content-filter .radio-inline label {
  padding-left: 30px; }

.nav-tabs-filter > li.active > a,
.nav-tabs-filter > li.active > a:hover,
.nav-tabs-filter > li.active > a:focus {
  border: none;
  border-radius: 0;
  background: none repeat scroll 0 0 #f7f7f9;
  color: #0093d5;
  padding: 10px 0; }

/********** Tiles **********/
.tile-item-wrapper {
  cursor: pointer;
  border: 1px solid #d9dbe2;
  border-radius: 2px;
  margin-bottom: 12px;
  padding: 10px 12px;
  position: relative;
  transition: 0.2s ease-in-out; }
  .tile-item-wrapper input[type="radio"] {
    margin-left: 12px;
    margin-top: 0;
    padding: 9px;
    position: absolute; }
    .tile-item-wrapper input[type="radio"]:after {
      top: 3px;
      left: 3px; }
  .tile-item-wrapper > input[type="checkbox"] {
    margin-left: 12px;
    margin-top: 6px;
    padding: 9px;
    position: absolute; }
    .tile-item-wrapper > input[type="checkbox"]:checked:after {
      top: 1px;
      left: 0px; }
  .tile-item-wrapper .tile-item-detail {
    margin-top: 12px; }
  .tile-item-wrapper.tile--has-action .tile-item-action {
    border-top: 1px solid #0093d5;
    min-width: 100%;
    margin: 0 -12px -12px -12px;
    line-height: 25px;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: bottom;
    padding: 0 1.2rem;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition);
    transition-duration: 200ms, 200ms, 200ms;
    transition-property: transform, opacity, padding; }
    .tile-item-wrapper.tile--has-action .tile-item-action .form-group, .tile-item-wrapper.tile--has-action .tile-item-action .checkbox {
      margin-bottom: 0; }

.tile-item-wrapper.tile-sm {
  min-height: 112px; }

.tile-item-wrapper.tile-md {
  min-height: 140px; }

.tile-item-wrapper.tile-lg {
  min-height: 167px; }

.tile-item-wrapper.active,
.tile-item-wrapper:hover {
  background: #e4f7ff;
  transition: 0.1s ease-in-out; }

.tile-item-wrapper.selected {
  background: white;
  border-color: #0093d5; }
  .tile-item-wrapper.selected.tile--has-action .tile-item-icon {
    margin: 0 auto; }
  .tile-item-wrapper.selected.tile--has-action .tile-item-action {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    opacity: 1;
    transform: scaleY(1); }
  .tile-item-wrapper.selected:hover {
    background: #e4f7ff;
    transition: 0.1s ease-in-out; }

.tile-item-wrapper.tile-item__check {
  display: none; }

.tile-item-wrapper.disabled {
  border-color: #e4e4e4;
  cursor: not-allowed;
  background-color: #f9f9f9; }

.tile-item-wrapper.disabled div {
  color: #999999; }

.tile-item-wrapper > div:not(.clear):not(.tile-item__check):not(.tile-item-action) {
  margin-bottom: 6px; }

.tile-item-wrapper > input {
  margin-bottom: 10px; }

.tile-item-wrapper .icon-info {
  position: absolute;
  right: 6px;
  top: 8px; }

.tile-item-wrapper .tile-item-icon {
  margin: 14px auto;
  max-height: 45px;
  text-align: center;
  height: 45px;
  font-family: 'o2font' !important;
  font-size: 40px;
  color: #000066;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 200ms;
  transition-property: margin; }

.tile-item-wrapper .tile-item-name {
  color: #0d8fc9;
  font-weight: 600; }

.tile-item-wrapper .tile-item-name.tooltip-wrap {
  display: block;
  position: static; }

.tile-item-wrapper .tooltip-wrap .tooltip {
  opacity: 1;
  bottom: calc(100% - 6px);
  right: auto; }
  .tile-item-wrapper .tooltip-wrap .tooltip.bottom {
    bottom: auto; }

.tile-item-wrapper .tooltip-wrap .tooltip-inner {
  background-color: rgba(92, 92, 92, 0.9); }

.tile-item-wrapper .tooltip-wrap .tooltip-arrow {
  display: none; }

.tile-item-wrapper .question-mark .tooltip {
  bottom: 22px;
  right: -88px; }
  @media (min-width: 768px) {
    .tile-item-wrapper .question-mark .tooltip {
      right: -140px; } }
  @media (min-width: 992px) {
    .tile-item-wrapper .question-mark .tooltip {
      right: -17px; } }
  .tile-item-wrapper .question-mark .tooltip .tooltip-arrow {
    right: 86px; }
    @media (min-width: 768px) {
      .tile-item-wrapper .question-mark .tooltip .tooltip-arrow {
        right: 138px; } }
    @media (min-width: 992px) {
      .tile-item-wrapper .question-mark .tooltip .tooltip-arrow {
        right: 15px; } }
  .tile-item-wrapper .question-mark .tooltip.bottom {
    top: 40px; }

.tile-item-wrapper .tile-item-detail {
  color: #525977; }
  .tile-item-wrapper .tile-item-detail .question-mark {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: -6px; }
    .tile-item-wrapper .tile-item-detail .question-mark:before {
      top: 3px;
      left: 0; }

.tile-item-wrapper .tooltip-wrap .tooltip-dots {
  border-bottom: 1px dotted #0093d5; }

.tile-item-wrapper .tooltip-wrap.tile-item-detail .tooltip-dots {
  border-color: #3b4653; }

.tile-item-price {
  display: inline-block; }

.tile-item-price {
  margin-right: 2px; }

.tile-item-wrapper .tile-item-icon.shipping_icon_store:before {
  content: "\e9c2"; }

.tile-item-wrapper .tile-item-icon.shipping_icon_post:before {
  content: "\e949"; }

.tile-item-wrapper .tile-item-icon.shipping_icon_courier:before {
  content: "\e9c1"; }

.tile-item-wrapper .tile-item-icon.payment_icon_pick_up:before {
  content: "\e9c0"; }

.tile-item-wrapper .tile-item-icon.payment_icon_online:before {
  content: "\e904"; }

.tile-item-wrapper .tile-item-icon.billing_icon_ebill:before {
  content: "\e967"; }

.tile-item-wrapper .tile-item-icon.billing_icon_paper_bill:before {
  content: "\e9bf"; }

@media (max-width: 767px) {
  .tile-item-wrapper .tooltip-wrap .tooltip {
    display: none; } }

.nav-icon {
  margin: 22px auto 22px;
  width: 64px;
  height: 64px;
  background: url(../images/nav-icons.svg) center 12px no-repeat; }

.nav-icon.zoznam-faktur {
  background-position: -144px -72px; }

.nav-icon.elektr-faktur {
  background-position: 0px 0px; }

.nav-icon.zmena-zabezp {
  background-position: 0px -72px; }

.nav-icon.faktur-profil {
  background-position: -216px -216px; }

.nav-icon.faktur-udaje {
  background-position: -288px -144px; }

.nav-icon.domov {
  background-position: -288px -72px; }

.nav-icon.analyz-spotreby {
  background-position: -144px -144px; }

.nav-icon.cez-internet {
  background-position: -360px -144px; }

.nav-icon.cez-fakturu {
  background-position: 0px -144px; }

.nav-icon.ine-moznosti {
  background-position: -288px -216px; }

.nav-icon.historia-hovorov {
  background-position: 0px -288px; }

.nav-icon.historia-dobijania {
  background-position: -144px -288px; }

.nav-icon.aktiv-sim {
  background-position: -216px -288px; }

.nav-icon.sprava-cudz {
  background-position: -288px -288px; }

.nav-icon.stav-obj {
  background-position: -72px -503px; }

.nav-icon.sms-brana {
  background-position: -360px -288px; }

.nav-icon.adresar {
  background-position: -144px -216px; }

.nav-icon.historia-sms {
  background-position: -216px -360px; }

.nav-icon.sprava-skupin {
  background-position: -288px -360px; }

.nav-icon.imp-exp {
  background-position: -360px -360px; }

.nav-icon.zmluvne-udaje {
  background-position: -0px -360px; }

.nav-icon.ostatne-udaje {
  background-position: -360px -216px; }

.nav-icon.prehlad-cisel {
  background-position: -72px -216px; }

.nav-icon.nastav-prav {
  background-position: -72px -144px; }

.nav-icon.hromadna-aktiv {
  background-position: -360px 0; }

.nav-icon.aktual-spotreba {
  background-position: -288px 0; }

.nav-icon.balicky-sluzby {
  background-position: -72px -72px; }

.nav-icon.net-balicky {
  background-position: -216px -144px; }

.nav-icon.moje-hovory {
  background-position: -144px 0; }

.nav-icon.zmena-programu {
  background-position: -72px -360px; }

.nav-icon.zlavove-kupony {
  background-position: -360px -503px; }

.nav-icon.zlavove-kupony {
  background-position: -360px -503px; }

.nav-icon.nastavenie-sluzieb {
  background-position: -360px -216px; }

.nav-icon.firemny-kontakt {
  background-position: -288px -503px; }

.nav-icon.nastenka {
  background-position: -288px -432px; }

.nav-icon.bezpecn-kod {
  background-position: -72px -288px; }

.nav-icon.overenie {
  background-position: -72px -288px; }

.nav-icon.zaslanie {
  background-position: 0px -432px; }

.nav-icon.zmena {
  background-position: -72px -432px; }

.nav-icon.najc-otazky {
  background-position: -144px -432px; }

.nav-icon.nove-moznosti {
  background-position: -216px -432px; }

.nav-icon.demoverzia {
  background-position: -360px -432px; }

.nav-icon.zakladne-udaje {
  background-position: 0px -503px; }

.nav-icon.udaje-eshop {
  background-position: -216px -503px; }

.nav-icon.prenos {
  background-position: -144px -503px; }

.nav-icon.zapamatanie {
  background-position: 0px -584px; }

.tiles--clickable .tile {
  text-decoration: none !important; }
  .tiles--clickable .tile .box {
    border-radius: 2px;
    min-height: 86px;
    display: flex;
    align-items: center;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition);
    transition-duration: 0.2s;
    padding: 15px; }
    .tiles--clickable .tile .box .tile__icon {
      font-size: 38px;
      color: #0093d5;
      margin-right: 12px;
      top: 3px;
      position: relative; }
      @media screen and (min-width: 1200px) {
        .tiles--clickable .tile .box .tile__icon {
          margin-right: 24px; } }
  .tiles--clickable .tile.tile--big .box {
    min-height: 170px;
    flex-direction: column;
    align-items: flex-start; }
    .tiles--clickable .tile.tile--big .box .tile__icon {
      font-size: 50px;
      flex-grow: 1; }
    .tiles--clickable .tile.tile--big .box .tile__title {
      font-size: 18px;
      font-weight: 400; }
  .tiles--clickable .tile.tile--no-icon .box {
    justify-content: center; }
    .tiles--clickable .tile.tile--no-icon .box .tile__title {
      font-size: 16px;
      font-weight: 400; }
  .tiles--clickable .tile.tile--textbox .box {
    flex-direction: column;
    align-items: flex-start; }
    .tiles--clickable .tile.tile--textbox .box .tile__title {
      font-size: 18px;
      font-weight: 400; }
      .tiles--clickable .tile.tile--textbox .box .tile__title + .tile__text {
        margin-top: 12px; }
    .tiles--clickable .tile.tile--textbox .box .tile__text {
      font-weight: 400; }
  .tiles--clickable .tile:hover .box-border, .tiles--clickable .tile:hover .box-shadow, .tiles--clickable .tile:hover .box-grad {
    background-color: #e4f7ff; }

/* Rating star component */
#fivestar {
  margin-bottom: 20px; }

.detail-fivestar-text {
  vertical-align: middle;
  margin-right: 16px;
  margin-top: 1px; }

.detail-fivestar-elements {
  display: inline-block; }

.detail-fivestar-element {
  cursor: pointer;
  display: inline-block;
  height: 24px;
  width: 24px;
  position: relative;
  font-family: o2font; }

.detail-fivestar-element:before, .detail-fivestar-element.question-mark:before {
  content: "\e9c3";
  color: #ccc;
  font-size: 26px;
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  line-height: 1; }

.detail-fivestar-element.staron:before, .detail-fivestar-element.staron.question-mark:before {
  color: #ff8b2f; }

.detail-fivestar-element.hover:before, .detail-fivestar-element.question-mark.hover:before {
  background: linear-gradient(90deg, rgba(255, 139, 47, 0.5) 50%, rgba(255, 139, 47, 0.5) 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: auto;
  width: auto; }

.detail-fivestar-element.starhalf:before {
  background: linear-gradient(90deg, #ff8b2f 50%, #ccc 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: auto;
  width: auto; }

.detail-fivestar-element > .tooltip, .detail-fivestar-element.question-mark > .tooltip {
  width: 90px;
  bottom: 24px;
  right: auto;
  left: -12px; }
  .detail-fivestar-element > .tooltip > .tooltip-arrow, .detail-fivestar-element.question-mark > .tooltip > .tooltip-arrow {
    left: 50%; }
  .detail-fivestar-element > .tooltip > .tooltip-inner, .detail-fivestar-element.question-mark > .tooltip > .tooltip-inner {
    text-align: center; }

.detail-fivestar-element > .tooltip > .tooltip-arrow, .detail-fivestar-element.question-mark > .tooltip > .tooltip-arrow {
  left: 50%; }

/*Box O2 Odpovede*/
.box-o2-answers {
  padding: 24px 12px;
  border-top: 1px solid #e7e7e7; }

.box-o2-answers:last-child {
  border-bottom: 1px solid #e7e7e7; }

.box-o2-answers h3.box-o2-answers__title {
  min-height: 2.5em; }

.box-o2-answers:hover {
  background-color: #fefde8; }

.box-o2-answers .avatar {
  text-align: center;
  margin-bottom: 12px; }

.box-o2-answers .avatar > img {
  border-radius: 50%;
  border: 1px solid #ddd; }

.box-o2-answers .answer-label {
  margin: 0 auto 12px;
  display: table; }

.box-o2-answers .related-links {
  margin: 0 0 12px;
  display: table; }

.box-o2-answers .related-link {
  margin-bottom: 12px; }

.box-o2-answers .related-link > [class^="o2f-"], .box-o2-answers .related-links > [class*=" o2f-"] {
  font-size: 20px; }

.box-o2-answers .related-link > span {
  position: relative;
  top: -3px;
  margin-left: 5px; }

@media screen and (min-width: 768px) {
  .box-o2-answers .answer-label {
    margin-left: 0;
    margin-right: 0; } }

.overlay__wrapper {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 10; }

.overlay__wrapper .overlay__box {
  text-align: center;
  position: relative;
  top: 35%;
  opacity: 1;
  color: white; }

.overlay__wrapper .overlay__title {
  font-size: 60px;
  color: white; }

.overlay__wrapper .overlay__subtitle {
  font-size: 26px;
  color: white; }

.overlay__wrapper .overlay__icon {
  font-size: 80px;
  line-height: 1;
  top: -3px;
  position: relative; }

.global-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 99999999; }

.global-overlay .global-overlay__box {
  text-align: center;
  opacity: 1;
  color: white; }

.global-overlay .global-overlay__title {
  font-size: 60px;
  color: white; }

.global-overlay .global-overlay__subtitle {
  font-size: 26px;
  color: white; }

.global-overlay .global-overlay__icon {
  font-size: 80px; }

.global-overlay--element-parent {
  position: relative; }

.global-overlay.global-overlay--element {
  position: absolute; }

.packages-wrapper {
  padding-bottom: 24px; }

.package {
  position: relative;
  padding: 12px;
  margin-bottom: 6px;
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-color: #F2F3F5; }
  .package .package__edit-icon {
    display: table;
    text-decoration: none;
    float: right; }
    .package .package__edit-icon [class^="o2f-"], .package .package__edit-icon [class*=" o2f-"], .package .package__edit-icon [class^="o2f-"] *, .package .package__edit-icon [class*=" o2f-"] * {
      color: #0093d5;
      font-size: 23px; }
    .package .package__edit-icon:hover, .package .package__edit-icon:focus {
      text-decoration: none;
      color: #2485c4; }
  .package .package__detail-link {
    display: table;
    float: right; }
    @media screen and (max-width: 450px) {
      .package .package__detail-link {
        margin-top: 16px; } }
  .package .o2-switch {
    float: right; }
    .package .o2-switch > input + label {
      margin-bottom: 0;
      margin-left: 12px; }

.package:hover {
  background-color: #fefde8; }

.package form {
  margin: 0 0 12px 0; }

.package form label.control-label {
  color: #343434; }

.package p:last-of-type {
  margin-bottom: 0;
  line-height: 1.4; }

.package.package--info {
  border-left-color: #0093d5; }

.package.package--success {
  border-left-color: #3bb237; }

.package.package--warning {
  border-left-color: #ff8b2f; }

.package.package--danger {
  border-left-color: #e82b3b; }

.package.package--process {
  border-left-color: #f2f3f5; }

.package .package__title {
  color: #343434; }
  .package .package__title .package__price {
    display: block; }
  .package .package__title .label {
    margin-bottom: 6px; }
  @media (min-width: 768px) {
    .package .package__title .package__price {
      display: inline; }
    .package .package__title .label {
      margin-left: 12px;
      margin-bottom: 0; } }

.package .package__description {
  margin-top: 12px; }

.package .package__description.text-dots {
  display: inline-block;
  width: 100%;
  vertical-align: bottom; }

.package .read-more__container {
  margin-top: 8px; }

.package .package__offer {
  position: absolute;
  top: -17px;
  right: 2%;
  padding: 4px 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background-color: #e82b3b;
  color: white;
  z-index: 1; }

.package .package__offer::before {
  content: '';
  top: 0;
  left: -4px;
  position: absolute;
  border-bottom: 4px solid #9c111d;
  border-left: 4px solid transparent; }

.package .package__offer--image {
  position: absolute;
  top: -17px;
  right: 2%;
  z-index: 1; }

.package .tooltip-inline {
  border-bottom: 1px dotted #343434;
  color: inherit; }

@media (max-width: 767px) {
  .package .package__offer {
    position: relative;
    display: table;
    left: 0;
    max-height: 26px; }
  .package .package__offer--image {
    position: relative;
    display: table;
    left: 0;
    max-height: 35px;
    -o-object-fit: contain;
    -ms-object-fit: contain;
    -webkit-object-fit: contain;
    -moz-object-fit: contain;
    object-fit: contain;
    -o-object-position: left;
    -ms-object-position: left;
    -webkit-object-position: left;
    -moz-object-position: left;
    object-position: left; } }

.tow-arrow-menu {
  margin-bottom: 12px; }

.tow-arrow-select {
  flex: 1;
  text-align: center; }

.tow-arrow-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  visibility: visible; }

.tow-arrow-select.native-select > select.selectpicker {
  background-color: white;
  text-align: center;
  -moz-text-align-last: center;
       text-align-last: center;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  height: 44px; }

.tow-arrow-select select::-ms-expand {
  display: none; }

.tow-arrow-select > .bootstrap-select.btn-group .dropdown-menu li > a {
  background-color: white; }

.tow-arrow-select > .bootstrap-select > button.selectpicker {
  border-radius: 0 !important;
  padding: 12px 12px 12px 12px;
  border: 1px solid #d9dbe2;
  border-left: none;
  border-right: none;
  background-color: white;
  margin-bottom: 0; }

.tow-arrow-select > .bootstrap-select > button.selectpicker:focus {
  background-color: white; }

.tow-arrow-select > .bootstrap-select > button.selectpicker .glyphicon-chevron-down {
  display: none; }

.tow-arrow-select > .bootstrap-select > button.selectpicker span.filter-option {
  text-align: center; }

.tow-arrow-select > .bootstrap-select > .dropdown-menu {
  top: 100%; }

@-moz-document url-prefix() {
  .tow-arrow-select > .bootstrap-select:not(.multilevel) > button.selectpicker .filter-option {
    left: 0px;
    position: static !important;
    padding-right: 0px; }
  .tow-arrow-select > .bootstrap-select:not(.multilevel) .btn.dropdown-toggle {
    height: inherit; }
  .tow-arrow-select > .bootstrap-select .btn.dropdown-toggle::-moz-focus-inner {
    margin-left: 0px; } }

.left-arrow-btn,
.right-arrow-btn {
  position: relative; }

.right-arrow-btn .btn-arrow {
  border-radius: 0px 2px 2px 0px;
  border: 1px solid #d9dbe2 !important; }

.left-arrow-btn .btn-arrow {
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #d9dbe2 !important; }

.btn-arrow {
  min-width: 29px;
  min-height: 100%;
  background: white;
  margin: 0px !important; }

.btn-arrow:not(.disabled):hover {
  background-color: #e4f7ff; }

.tow-arrow-menu,
.tow-arrow-menu > * {
  display: flex; }

.tow-arrow-menu .btn {
  margin-top: 0;
  min-width: 50px; }

div.right-arrow-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2485c4; }

div.left-arrow-btn span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2485c4; }

.tow-arrow-menu .bootstrap-select.btn-group:not(.input-group-btn) {
  margin-bottom: 0px; }

.tow-arrow-menu .btn-arrow .glyphicon,
.tow-arrow-menu .btn-arrow [class^='o2f'] {
  font-weight: bold;
  font-size: 24px;
  top: 24px; }

.cart-wrapper {
  position: relative;
  border-radius: 2px; }

.cart {
  line-height: 1.42857143;
  background-color: white;
  border-radius: 2px;
  box-shadow: rgba(102, 102, 102, 0.4) 0px 1px 3px;
  position: relative; }

.cart.cart--removable-items .cart__item:hover .cart__item__price {
  display: none; }

.cart.cart--removable-items .cart__item:hover .cart__item__trash {
  display: block; }

.cart .header {
  padding: 16px 12px;
  background-color: #F2F3F5;
  color: #343434; }
  .cart .header h5, .cart .header h6 {
    margin: 0; }

.cart .cart__inner {
  padding: 12px; }

.cart.cart--order {
  margin-bottom: 24px; }
  .cart.cart--order .header a {
    margin-left: 0; }
    @media (min-width: 992px) {
      .cart.cart--order .header a {
        margin-left: 12px; } }
  .cart.cart--order .product {
    padding: 12px; }
    .cart.cart--order .product .product-image {
      max-width: 100%;
      max-height: 120px; }
      @media (min-width: 992px) {
        .cart.cart--order .product .product-image {
          max-height: 90px; } }
  .cart.cart--order .total td:first-of-type {
    text-align: left; }

.cart--recapitulation {
  margin-bottom: 24px; }
  .cart--recapitulation .product .cart__item [class*="o2f-"]:not(.cart__item__trash) {
    display: inline-block !important; }

.cart .product {
  padding: 10px 0 0 0; }
  .cart .product > h6 {
    padding-left: 10px;
    padding-right: 10px; }
  .cart .product > .cart__item {
    transition: 0.15s ease;
    padding: 3px 10px;
    position: relative; }
    .cart .product > .cart__item.cart__item__hover {
      background: #fefde8; }
    .cart .product > .cart__item:first-child .cart__item__trash {
      top: 10px; }
    .cart .product > .cart__item .cart__item__price {
      display: block; }
    .cart .product > .cart__item .cart__item__trash {
      position: absolute;
      right: 6px;
      top: 3px;
      width: 30px;
      height: 20px;
      font-size: 18px;
      color: #0093d5;
      background-color: #fefde8;
      text-align: center;
      transition: 0.15s ease;
      display: none;
      cursor: pointer; }
    .cart .product > .cart__item [class*="o2f-"]:not(.cart__item__trash) {
      display: table-cell;
      padding-right: 3px;
      padding-top: 2px;
      vertical-align: top; }
    .cart .product > .cart__item .cart__item__name {
      display: table-cell;
      vertical-align: top; }
    @media (max-width: 449px) {
      .cart .product > .cart__item .cart__monthly-text {
        display: none; }
      .cart .product > .cart__item .cart__monthly-text + .text-right {
        width: 33.33%; } }

.cart .total {
  padding: 12px 10px;
  background-color: #F2F3F5; }
  .cart .total .tooltip {
    right: 0; }
  .cart .total .btn {
    margin-top: 12px; }
  .cart .total .total__desc {
    text-align: left; }
  .cart .total .total__money {
    text-align: right; }
  @media (min-width: 768px) {
    .cart .total .total__desc {
      text-align: right; } }
  .cart .total .table tr:hover {
    background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
    background: initial; }
  .cart .total .table td {
    margin: 0;
    padding: 0;
    border: none; }
  .cart .total .table td:last-of-type {
    text-align: right; }

.cart h5,
.cart p {
  margin: 0; }

.cart h6 {
  margin-top: 0px;
  margin-bottom: 6px; }

.cart hr {
  margin: 0; }

.inline-alert {
  display: block;
  padding: 6px 10px; }

.inline-alert.inline-alert--info {
  background-color: #0093d5;
  color: white; }
  .inline-alert.inline-alert--info [class*="o2f-"] {
    color: white; }
  .inline-alert.inline-alert--info .tooltip-wrap .tooltip-dots {
    border-bottom-color: white; }

.cart.cart--mini-shopping-cart .cart__item:hover {
  background-color: #fefde8;
  transition: 0.15s ease; }

.cart.cart--mini-shopping-cart .product {
  padding: 0; }
  .cart.cart--mini-shopping-cart .product .cart__item:first-child {
    padding-top: 10px; }

.table-hp-info .summary {
  background: #f7f7f7; }

.table-hp-info .question-mark {
  width: 16px;
  height: 16px; }
  .table-hp-info .question-mark:before {
    top: -4px;
    left: 0px; }

.cart.flash {
  -webkit-animation: flash 1s ease-out;
  animation: flash 1s ease-out; }

@media (max-width: 991px) {
  .cart .header h5, .cart .header h6 {
    line-height: 1.4; } }

@media (min-width: 768px) {
  .cart .header .is-flex .has-actions {
    position: absolute;
    right: 6px; }
  .cart .header .is-flex .has-actions {
    position: absolute;
    right: 6px; }
  .cart .header .is-flex .has-padding-right {
    padding-right: 85px; } }

@media (max-width: 767px) {
  .cart.cart--mini-shopping-cart {
    position: absolute;
    display: none;
    bottom: 44px;
    width: 100%;
    z-index: 1000; }
  .cart--fixed .cart {
    position: fixed;
    width: 100%;
    left: 0;
    border-radius: 0px;
    box-shadow: 0 -4px 10px #ddd;
    z-index: 10001;
    display: none;
    max-height: calc(100% - 48px);
    overflow-y: scroll; }
    .cart--fixed .cart > .total > button {
      display: none; }
  .cart--fixed .cart__total-mobile {
    position: fixed;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.35); }
    .cart--fixed .cart__total-mobile > button {
      border-radius: 0; }
    .cart--fixed .cart__total-mobile > .cart__total-mobile--right {
      border-radius: 0; }
  .cart__total-mobile {
    width: 100%;
    position: relative;
    height: 44px;
    bottom: 0;
    left: 0;
    z-index: 10000;
    margin-bottom: 0; }
    .cart__total-mobile > button {
      position: absolute;
      z-index: 10000;
      width: calc(100% - 78px);
      left: 0;
      border-radius: 2px 0 0 2px; }
    .cart__total-mobile > .cart__total-mobile--right {
      width: 78px;
      height: 44px;
      position: absolute;
      z-index: 10001;
      right: 0;
      background: #0093d5;
      text-align: center;
      border-left: 1px solid #005177;
      border-radius: 0px 2px 2px 0; }
      .cart__total-mobile > .cart__total-mobile--right > .o2f-chevron-top {
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 28px;
        position: absolute;
        width: 44px;
        height: 44px;
        transition: all .4s ease;
        left: 34px; }
      .cart__total-mobile > .cart__total-mobile--right > .o2f-chevron-top.opened {
        transform: rotate(-180deg);
        transform-origin: 50% 50%; }
      .cart__total-mobile > .cart__total-mobile--right > .o2f-chevron-top:before {
        font-size: 24px;
        color: white;
        position: relative;
        top: 6px; }
      .cart__total-mobile > .cart__total-mobile--right > .cart__total-mobile--right__number {
        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        position: absolute;
        top: 10px;
        left: 10px;
        color: white; }
      .cart__total-mobile > .cart__total-mobile--right > .cart__total-mobile--right__number.flash {
        -webkit-animation: flash-mobile 1s ease-out;
        animation: flash-mobile 1s ease-out; }
    .cart__total-mobile > .cart__total-mobile--right:hover {
      background: #005177; } }

@keyframes flash {
  0% {
    background-color: white; }
  50% {
    background-color: #fefde8; }
  100% {
    background-color: white; } }

@-webkit-keyframes flash {
  0% {
    background-color: white; }
  50% {
    background-color: #fefde8; }
  100% {
    background-color: white; } }

@keyframes flash-mobile {
  0% {
    background-color: rgba(255, 255, 255, 0.2);
    color: white; }
  50% {
    background-color: white;
    color: #343434; }
  100% {
    background-color: rgba(255, 255, 255, 0.2); } }

@-webkit-keyframes flash-mobile {
  0% {
    background-color: rgba(255, 255, 255, 0.2); }
  50% {
    background-color: white;
    color: #343434; }
  100% {
    background-color: rgba(255, 255, 255, 0.2); } }

.swiper-container {
  width: 100%; }
  .swiper-container .swiper-slide {
    text-align: center;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 300px; }
    .swiper-container .swiper-slide .slide-bg {
      position: absolute;
      z-index: -1;
      height: 100%;
      width: 100%;
      top: 0; }
      .swiper-container .swiper-slide .slide-bg * {
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        height: 100%; }
    .swiper-container .swiper-slide .slide-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-left: 12px;
      padding-right: 12px; }
      @media only screen and (min-width: 992px) {
        .swiper-container .swiper-slide .slide-content {
          padding: 0; } }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .swiper-container .swiper-slide .container {
        margin: 0; } }

@media screen and (-ms-high-contrast: active) and (max-width: 767px), screen and (-ms-high-contrast: none) and (max-width: 767px) {
  .swiper-container .swiper-slide .container, .swiper-container .swiper-slide .text, .swiper-container .swiper-slide .image {
    width: 100%; } }
  .swiper-container .swiper-button-next {
    right: 12px; }
  .swiper-container .swiper-button-prev {
    left: 12px; }
  .swiper-container .swiper-button-next.swiper-button-white,
  .swiper-container .swiper-container-rtl .swiper-button-prev.swiper-button-white, .swiper-container .swiper-button-prev.swiper-button-white,
  .swiper-container .swiper-container-rtl .swiper-button-next.swiper-button-white,
  .swiper-container .swiper-button-next,
  .swiper-container .swiper-container-rtl .swiper-button-prev,
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-container-rtl .swiper-button-next {
    background-image: none;
    width: 44px;
    height: 44px;
    text-align: center;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.25);
    border-radius: 2px; }
    .swiper-container .swiper-button-next.swiper-button-white:before,
    .swiper-container .swiper-container-rtl .swiper-button-prev.swiper-button-white:before, .swiper-container .swiper-button-prev.swiper-button-white:before,
    .swiper-container .swiper-container-rtl .swiper-button-next.swiper-button-white:before,
    .swiper-container .swiper-button-next:before,
    .swiper-container .swiper-container-rtl .swiper-button-prev:before,
    .swiper-container .swiper-button-prev:before,
    .swiper-container .swiper-container-rtl .swiper-button-next:before {
      line-height: 44px;
      width: 100%;
      position: relative;
      top: 3px; }
  .swiper-container .swiper-button-next[class^="o2f-"], .swiper-container .swiper-button-next[class*=" o2f-"], .swiper-container .swiper-button-prev[class^="o2f-"], .swiper-container .swiper-button-prev[class*=" o2f-"] {
    font-size: 34px;
    opacity: 0;
    color: #0093d5;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition);
    transition-duration: .2s; }
    .swiper-container .swiper-button-next[class^="o2f-"]:hover, .swiper-container .swiper-button-next[class*=" o2f-"]:hover, .swiper-container .swiper-button-prev[class^="o2f-"]:hover, .swiper-container .swiper-button-prev[class*=" o2f-"]:hover {
      color: #0070a2;
      background-color: #e4f7ff;
      box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.4); }
  .swiper-container:hover .swiper-button-next[class^="o2f-"], .swiper-container:hover .swiper-button-next[class*=" o2f-"], .swiper-container:hover .swiper-button-prev[class^="o2f-"], .swiper-container:hover .swiper-button-prev[class*=" o2f-"] {
    opacity: 1; }
  .swiper-container .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: inline-block;
    border-radius: 100%;
    background: #f2f2f2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    opacity: 1;
    margin: 0 6px !important; }
    .swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #3399cc; }
  .swiper-container[data-type="title-banner"] .swiper-slide {
    padding: 12px 12px 38px 12px; }
    @media screen and (max-width: 767px) {
      .swiper-container[data-type="title-banner"] .swiper-slide {
        align-items: flex-start;
        min-height: 400px; } }
    @media screen and (min-width: 768px) and (max-width: 971px) {
      .swiper-container[data-type="title-banner"] .swiper-slide {
        max-height: 300px;
        align-items: center; } }
    @media screen and (min-width: 972px) {
      .swiper-container[data-type="title-banner"] .swiper-slide {
        max-height: 400px;
        align-items: center; } }
  .swiper-container[data-type="title-banner"] .slide-content.row {
    width: auto; }
    @media only screen and (max-width: 767px) {
      .swiper-container[data-type="title-banner"] .slide-content.row {
        flex-direction: column; } }
    @media only screen and (min-width: 768px) {
      .swiper-container[data-type="title-banner"] .slide-content.row .text {
        text-align: left; } }
    @media only screen and (max-width: 767px) {
      .swiper-container[data-type="title-banner"] .slide-content.row .image {
        position: absolute;
        bottom: 0;
        z-index: -1; } }
  @media only screen and (max-width: 767px) and (-ms-high-contrast: active), only screen and (max-width: 767px) and (-ms-high-contrast: none) {
    .swiper-container[data-type="title-banner"] .slide-content.row .image {
      left: 0;
      right: 0; } }
  .swiper-container[data-type="title-banner"] .slide-content h1, .swiper-container[data-type="title-banner"] .slide-content p {
    color: white; }
  @media only screen and (max-width: 767px) {
    .swiper-container[data-type="title-banner"] .slide-content h1 {
      font-size: 22px; } }
  .swiper-container[data-type="title-banner"] .slide-bg + .slide-content {
    padding-left: 12px;
    padding-right: 12px; }
  .swiper-container[data-type="title-banner"] .swiper-button-next[class^="o2f-"], .swiper-container[data-type="title-banner"] .swiper-button-next[class*=" o2f-"], .swiper-container[data-type="title-banner"] .swiper-button-prev[class^="o2f-"], .swiper-container[data-type="title-banner"] .swiper-button-prev[class*=" o2f-"] {
    color: white; }

.ie-object-fit {
  position: relative;
  background-size: cover;
  background-position: center center; }

.ui-timepicker.ui-widget {
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
  border-top: none;
  border-radius: 2px;
  overflow: hidden;
  z-index: 8031 !important; }
  .ui-timepicker.ui-widget .ui-timepicker-title {
    font-size: 0.9em;
    background: white;
    border: none;
    text-align: center;
    border-radius: 0;
    padding: 11px;
    color: #343434; }
  .ui-timepicker.ui-widget .ui-timepicker-hours .ui-timepicker-title {
    border-right: 1px solid #d9dbe2; }
  .ui-timepicker.ui-widget .ui-timepicker-hours .ui-timepicker {
    border-right: 1px solid #d9dbe2; }
  .ui-timepicker.ui-widget .ui-timepicker-table {
    border: none;
    min-width: 296px; }
    .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker {
      width: 100%;
      text-align: center;
      background: white; }
      .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker td {
        width: 32px;
        height: 32px; }
      .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker .ui-timepicker-space td {
        width: 5px;
        height: 5px; }
      .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker .ui-state-default {
        background: none;
        border: none; }
      .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker .ui-state-hover:before {
        border: 1px solid #0093d5;
        background: white; }
      .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker .ui-state-active {
        color: white; }
        .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker .ui-state-active:before {
          background: #0095db; }
      .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker a {
        position: relative;
        text-decoration: none;
        font-size: 0.9em;
        z-index: 1; }
        .ui-timepicker.ui-widget .ui-timepicker-table .ui-timepicker a:before {
          content: ' ';
          position: absolute;
          width: 29px;
          height: 29px;
          border-radius: 50%;
          top: 50%;
          left: 52%;
          transform: translate(-50%, -50%);
          z-index: -1; }

.ui-picker-btn {
  cursor: pointer; }
  .ui-picker-btn [class^='o2f-'] {
    color: #848485;
    top: 2px;
    position: relative; }

.datepicker-label > input[type=text] ~ label.control-label {
  font-size: 12px;
  color: #999;
  padding: 0px;
  position: absolute;
  top: 7px;
  left: 12px; }

.datepicker-container {
  position: relative;
  height: 0px;
  width: 100%;
  left: 0;
  bottom: 0; }

.datepicker-container .form-control {
  border: none;
  padding: 0; }

.datepicker-container .ui-datepicker .ui-datepicker-title {
  margin: 0 95px 0 0;
  text-align: left;
  height: 56px; }

.datepicker-container .ui-datepicker .ui-datepicker-title select {
  margin: 0; }

.datepicker-container .ui-datepicker select.ui-datepicker-month,
.datepicker-container .ui-datepicker select.ui-datepicker-year {
  height: 100%;
  width: 100%;
  color: #343434;
  font-size: 16px;
  line-height: 1.5;
  text-transform: none;
  padding: 0px 25px 0px 30px; }

.datepicker-container.half-width .ui-datepicker select.ui-datepicker-month,
.datepicker-container.half-width .ui-datepicker select.ui-datepicker-year {
  width: auto; }

.datepicker-container .ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  box-shadow: none; }

.datepicker-container .ui-widget-header {
  border: none; }

.ui-datepicker {
  border: 0;
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
  border-radius: 2px;
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  padding: 0;
  z-index: 5000 !important; }

.ui-widget.ui-widget-content {
  border: 0; }

.ui-datepicker .ui-datepicker-header {
  padding: 0px; }

.ui-datepicker-header {
  background: white;
  border-radius: 0;
  height: 44px; }

.ui-datepicker-header .ui-datepicker-title {
  color: white;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 500;
  height: 44px; }

html.ie .ui-datepicker-header .ui-datepicker-title {
  color: black; }

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: auto;
  background: inherit;
  border: none;
  font-family: 'Open Sans';
  cursor: pointer;
  line-height: normal; }

html.ie .ui-datepicker select.ui-datepicker-month,
html.ie .ui-datepicker select.ui-datepicker-year {
  background: white; }

.ui-datepicker-header select.ui-datepicker-month,
.ui-datepicker-header select.ui-datepicker-year {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../images/arrow-down-gray.png") no-repeat 90% 50%; }

.ui-datepicker-header select.ui-datepicker-month::-ms-expand,
.ui-datepicker-header select.ui-datepicker-year::-ms-expand {
  display: none; }

.ui-datepicker-header select.ui-datepicker-month option,
.ui-datepicker-header select.ui-datepicker-year option {
  background: #f2f3f5; }

html.ie .ui-datepicker-header select.ui-datepicker-month option,
html.ie .ui-datepicker-header select.ui-datepicker-year option {
  background: white;
  color: black; }

.datepicker-container .ui-datepicker-prev.ui-corner-all {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none; }

.datepicker-container .ui-datepicker-next.ui-corner-all {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: none; }

.datepicker-container .ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.datepicker-container table.ui-datepicker-calendar {
  background-color: white; }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-active {
  color: white; }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-hover:before,
.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-highlight:before,
.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-active:before {
  content: ' ';
  position: absolute;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-highlight.ui-state-hover:not(.ui-state-active):before {
  background: white; }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-hover:before {
  background: white;
  border: 1px solid #0093d5; }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-highlight:before {
  background: rgba(0, 147, 213, 0.15); }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-active:before {
  background: #0093d5;
  border: 1px solid #0093d5; }

.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-highlight,
.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-hover,
.datepicker-container .ui-datepicker-calendar .ui-state-default.ui-state-active {
  position: relative;
  z-index: 1; }

.ui-datepicker .ui-datepicker-prev {
  left: 0px;
  min-width: 40px;
  position: absolute;
  height: 56px;
  top: 0px;
  left: auto;
  right: 55px; }

.ui-datepicker .ui-datepicker-next {
  float: right;
  right: 0px;
  min-width: 40px;
  position: absolute;
  height: 56px;
  top: 0px;
  right: 15px; }

.ui-datepicker-prev {
  border: 1px solid #d9dbe2;
  display: inline-block;
  width: 6px;
  height: auto;
  text-align: center;
  cursor: pointer;
  background: url("../images/arrow-left-blue.png") no-repeat 18px 23px;
  line-height: 600%;
  overflow: hidden; }

.ui-datepicker-next {
  border: 1px solid #d9dbe2;
  display: inline-block;
  width: 6px;
  height: 11px;
  text-align: center;
  cursor: pointer;
  background: url("../images/arrow-right-blue.png") no-repeat 18px 23px;
  line-height: 600%;
  overflow: hidden; }

.ui-datepicker table {
  margin-top: 12px;
  margin-bottom: 12px; }

.ui-datepicker-calendar thead {
  border-left: none;
  border-right: none;
  border-bottom: none; }

.ui-datepicker-calendar thead tr {
  background: white; }

.ui-datepicker-calendar thead tr th {
  border-radius: 0;
  color: black;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Open Sans'; }

.ui-datepicker-calendar tbody {
  border: none; }

.ui-datepicker-calendar tbody td {
  border: none;
  width: 30px;
  height: 30px; }

.ui-datepicker-calendar tbody td .ui-state-default {
  color: #5a5c5c;
  background: none;
  border: none;
  text-align: center; }

/*.ui-datepicker-calendar tbody td .ui-state-highlight,*/
.ui-datepicker-calendar tbody td .ui-state-active {
  color: white;
  /*background: #86cafd;*/ }

.datepicker {
  margin: 0; }

.datepicker td span {
  width: auto;
  height: auto;
  line-height: 15px;
  margin: 0;
  width: 30px;
  text-align: center; }

.ui-datepicker td {
  padding: 0; }

.ui-datepicker td a {
  text-align: center;
  padding: 6px 0; }

.ui-icon,
.ui-widget-content .ui-icon,
.ui-widget-header .ui-icon {
  background: none; }

.ui-datepicker-header a.ui-state-hover.ui-datepicker-prev-hover {
  background: url("../images/arrow-left-blue.png") no-repeat 18px 23px;
  border: none; }

.ui-datepicker-header a.ui-state-hover.ui-datepicker-next-hover {
  background: url("../images/arrow-right-blue.png") no-repeat 18px 23px;
  border: none; }

.datepicker thead tr:first-child th:hover {
  background: none;
  cursor: default; }

.datepicker ~ label.error-label.red {
  right: 56px;
  z-index: 9; }

.has-tooltip.tooltip-visible.datepicker ~ label.error-label.red {
  right: 95px;
  z-index: 9; }

.has-tooltip.tooltip-visible.datepicker ~ label.error-label.red,
.has-tooltip.tooltip-visible.timepicker ~ label.error-label.red,
.has-tooltip.tooltip-visible.appended ~ label.error-label.red {
  right: 95px;
  z-index: 9; }

.datepicker ~ .question-mark-input,
.timepicker ~ .question-mark-input,
.appended ~ .question-mark-input {
  right: 47px;
  z-index: 15; }

.icon-calendar:before {
  content: none; }

.icon-calendar {
  background: #f2f3f5 url("../images/calendar-icon-v2.png") no-repeat 13px 14px;
  cursor: pointer; }

.form-control.hasDatepicker:focus ~ .ui-picker-btn,
.form-control.hasTimepicker:focus ~ .ui-picker-btn {
  background: #e4f7ff; }

.hasDatepicker ~ .question-mark-input {
  right: 45px; }
  .hasDatepicker ~ .question-mark-input:before {
    top: 3px; }

.hasDatepicker.has-tooltip.tooltip-visible ~ .error-label.red {
  right: 90px; }

.input-group-addon.ui-picker-btn {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 0.2s; }
  .input-group-addon.ui-picker-btn:hover, .input-group-addon.ui-picker-btn:active {
    background: #e4f7ff; }

.activity-timeline {
  position: relative;
  margin-bottom: 12px; }
  .activity-timeline.activity-timeline--time .activity-timeline__list {
    margin-left: 50px; }
  .activity-timeline.activity-timeline--date .activity-timeline__list {
    margin-left: 80px; }
    .activity-timeline.activity-timeline--date .activity-timeline__list > .activity-timeline__item > .activity-timeline__time {
      left: -99px; }
  .activity-timeline.activity-timeline--datetime .activity-timeline__list {
    margin-left: 110px; }
    .activity-timeline.activity-timeline--datetime .activity-timeline__list > .activity-timeline__item > .activity-timeline__time {
      left: -128px; }
  .activity-timeline .activity-timeline__list {
    border-left: 1px solid #ddd;
    padding-left: 1.74rem; }
    .activity-timeline .activity-timeline__list.activity-timeline__list--single {
      border: none; }
    .activity-timeline .activity-timeline__list.activity-timeline__list--inner {
      border: none;
      margin-left: 0;
      padding-left: 0; }
      .activity-timeline .activity-timeline__list.activity-timeline__list--inner > .activity-timeline__item {
        margin-bottom: 0; }
        .activity-timeline .activity-timeline__list.activity-timeline__list--inner > .activity-timeline__item::before {
          content: none; }
    .activity-timeline .activity-timeline__list > .activity-timeline__item {
      position: relative;
      margin-bottom: 24px; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item.active h5 {
        font-weight: 600; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item:last-of-type {
        margin-bottom: 0; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item h5 {
        margin-bottom: 6px;
        line-height: 1;
        font-weight: 400;
        font-size: 16px; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--success::before, .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--complete::before {
        background: #3bb237; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--active::before, .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--info::before {
        background: #0093d5; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--primary::before {
        background: #0093d5; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--warning::before {
        background: #ff8b2f; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item.activity-timeline__item--danger::before {
        background: #e82b3b; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        left: -25px;
        background: #ddd;
        border-radius: 7.5px; }
      .activity-timeline .activity-timeline__list > .activity-timeline__item > .activity-timeline__time {
        position: absolute;
        left: -68px;
        font-size: 12px;
        color: #7f7f7f; }

/* FancyBox */
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url("../images/fancybox/fancybox_sprite.png"); }

.fancybox-close {
  background-image: none;
  top: 0;
  right: 0;
  text-decoration: none !important; }
  .fancybox-close:after {
    font-family: o2font;
    content: '\e994';
    color: #343434;
    font-size: 28px;
    font-weight: normal;
    opacity: 0.4;
    transition: 0.1s ease-in-out; }
  .fancybox-close:hover:after {
    opacity: 1; }

.fancybox-overlay {
  background: rgba(0, 0, 0, 0.8); }

.fancybox-wrap .fancybox-skin {
  padding: 0 !important;
  background: white;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #979797; }
  .fancybox-wrap .fancybox-skin .fancybox-inner {
    overflow: visible !important;
    height: auto !important; }
    .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content {
      padding: 30px; }
      .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content.popup-small {
        width: 520px; }
      .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content.popup-large {
        width: 720px; }
      .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content > h2 {
        color: #333333; }
      .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content > p {
        margin: 25px 0;
        color: #3b4653; }
      .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content > h2 ~ p, .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content h3 ~ p, .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content h4 ~ p, .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content h5 ~ p {
        margin-top: 0;
        margin-bottom: 12px; }
      .fancybox-wrap .fancybox-skin .fancybox-inner .fancybox-popup-content > .btn {
        margin-right: 4px;
        margin-bottom: 4px; }

.fancybox-popup-content {
  display: none;
  width: 720px;
  text-align: left; }

.fancybox-type-iframe .fancybox-nav {
  width: 60px; }
  .fancybox-type-iframe .fancybox-nav:hover span {
    opacity: 1; }
  .fancybox-type-iframe .fancybox-nav span {
    visibility: visible;
    opacity: 0.5; }

.fancybox-type-iframe .fancybox-prev {
  left: -60px; }

.fancybox-type-iframe .fancybox-next {
  right: -60px; }

.slidebottom {
  display: block !important; }

.dropdown-menu, .msisdnSelector {
  padding: 0;
  border: 0;
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1); }
  .dropdown-menu li, .msisdnSelector li {
    border-bottom: 1px solid rgba(82, 109, 119, 0.1); }
    .dropdown-menu li:last-child, .msisdnSelector li:last-child {
      border-bottom: none; }
    .dropdown-menu li > a, .msisdnSelector li > a {
      outline: none;
      transition: 0.1s ease;
      padding: 8px 20px;
      font-size: 14px;
      color: #3b4653; }
      .dropdown-menu li > a:hover, .dropdown-menu li > a:focus:hover, .msisdnSelector li > a:hover, .msisdnSelector li > a:focus:hover {
        background-color: #e4f7ff; }
      .dropdown-menu li > a:focus, .msisdnSelector li > a:focus {
        background-color: white; }

.bootstrap-select.btn-group .dropdown-menu {
  top: 48px !important; }

.bootstrap-select.btn-group .dropdown-menu li:hover,
.bootstrap-select.btn-group .dropdown-menu li a:hover,
.msisdnSelector li:hover,
.msisdnSelector li a:hover {
  color: #0093d5; }

select.selectpicker ~ label.control-label {
  font-size: 12px;
  color: #7f7f7f;
  padding: 0px;
  position: absolute;
  top: 7px;
  left: 12px; }

@-moz-document url-prefix() {
  .bootstrap-select.has-error .selectpicker:not(ul) .filter-option {
    padding-left: 18px; } }

.mobile-selectpicker.disabled {
  cursor: not-allowed;
  background-color: #f2f3f5;
  border: 1px solid #d9dbe2;
  opacity: 1; }

.selectpicker.dropdown-menu {
  background-color: white; }

.selectpicker .glyphicon {
  padding: 16px;
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px; }

#selectbox {
  background: url("../images/selectbox.png"); }

.selectpicker.btn {
  padding: 18px 46px 5px 12px;
  color: #5c5c5c;
  margin-top: 0;
  white-space: nowrap; }

.selectpicker.btn.no-label,
.bootstrap-select.no-label > button.selectpicker {
  padding: 11px 12px 11px 12px; }

select.has-error + .bootstrap-select button {
  border-color: #e75555 !important;
  box-shadow: 0 0 0 1px #e75555 inset !important; }

.has-error.bootstrap-select {
  background: inherit;
  border-color: inherit;
  box-shadow: none;
  outline: none;
  padding-left: 0px; }

.bootstrap-select.btn-group {
  background-color: white; }

.bootstrap-select.btn-group .btn .filter-option {
  height: 20px; }

.selectFilter.ba_select_panel.col-sm-6 {
  margin-bottom: 20px; }

.bootstrap-select .select--readonly__change-link {
  display: none; }

.bootstrap-select.select--readonly {
  width: auto !important; }

.bootstrap-select.select--readonly > .selectpicker {
  border: 0;
  cursor: auto;
  width: auto;
  padding-right: 12px; }

.bootstrap-select.select--readonly > .selectpicker .glyphicon {
  display: none; }

.bootstrap-select.select--readonly:hover > .selectpicker.hover ~ label {
  color: #7f7f7f; }

.bootstrap-select.select--readonly .select--readonly__change-link {
  display: block; }

.bootstrap-select-searchbox input {
  border-radius: 2px !important; }

.bootstrap-select.btn-group .dropdown-menu.inner {
  overflow-x: hidden;
  border-radius: 2px; }

.bootstrap-select-searchbox {
  position: relative; }

.bootstrap-select-searchbox .glyphicon {
  position: absolute !important;
  right: 18px !important;
  top: 25px !important;
  font-size: 20px; }

.bootstrap-select .dropdown-menu li span.text {
  min-height: 1em;
  display: inline-block; }

.select-package .bootstrap-select .filter-option,
.products-sorting .bootstrap-select .filter-option,
.select-number .bootstrap-select .filter-option,
.new-number-select .bootstrap-select .filter-option,
.form-horizontal .bootstrap-select .filter-option {
  max-width: 100%;
  padding-right: 0;
  position: static; }

.input-with-action .bootstrap-select {
  padding-right: 23px; }

.dropup.open .dropdown-menu {
  z-index: 5000; }

/********** Multilevel select **********/
.bootstrap-select.multilevel .dropdown-menu .divider {
  margin: 0px; }

.bootstrap-select.multilevel .dropdown-menu dt {
  padding-top: 6px;
  padding-bottom: 6px; }

.bootstrap-select.multilevel .dropdown-menu dt:hover {
  cursor: pointer; }

.bootstrap-select.multilevel .dropdown-menu li {
  border-bottom: none; }

.bootstrap-select.multilevel .dropdown-menu .child.closed {
  display: none; }

.bootstrap-select.multilevel .dropdown-menu input[type=radio] {
  margin-top: -3px;
  margin-right: 12px;
  vertical-align: middle;
  position: relative; }

.bootstrap-select.multilevel.multiline .btn .filter-option {
  white-space: normal;
  padding: 1px 32px 1px 0;
  height: 100%; }

.bootstrap-select.multilevel .dropdown-menu li.root {
  background: transparent url(../images/eshop/arrow-down.png) 17px 17px no-repeat; }

.bootstrap-select.multilevel .dropdown-menu li.root.closed {
  background: transparent url(../images/eshop/arrow-right.png) 17px 16px no-repeat; }

.bootstrap-select.multilevel .dropdown-menu li.root:first-child {
  background-position: 8px 11px !important; }

.bootstrap-select.multilevel .dropdown-menu li.root.closed:first-child {
  background-position: 10px 10px !important; }

.checkmark {
  background: transparent url(../images/eshop/checkmark.png) center center no-repeat;
  width: 13px;
  height: 13px;
  display: inline-block; }

/****************************************************************
 Nested select component
****************************************************************/
.bootstrap-select li.root > dt {
  padding-left: 39px !important; }

.bootstrap-select li > a {
  padding-left: 12px; }

#hidablePanel .msisdnSelector li > a {
  padding-left: 15px; }

.bootstrap-select.btn-group .dropdown-menu li > a.opt {
  padding-left: 39px !important; }

#hidablePanel li > a {
  cursor: pointer; }

/****************************************************************
 Native select styles from bootstrap select
****************************************************************/
.native-select label.control-label {
  position: static; }

.native-select select.selectpicker {
  width: 100%;
  padding: 9px 12px 7px;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #d9dbe2; }

.optional-label {
  color: #999999; }

.group-label-small {
  padding: 10px 20px 10px 20px;
  border-top: 1px solid #dcdcdc; }

select.selectpicker + div.btn-group button.selectpicker.btn-default,
select.multiselect + div.btn-group button.multiselect.btn-default {
  color: #343434; }

.coupon-details span:last-child {
  margin-top: -1em;
  display: block; }

.has-tooltip .selectpicker .glyphicon {
  right: 44px; }

@-moz-document url-prefix() {
  .bootstrap-select .glyphicon.glyphicon-chevron-down {
    right: -1px; }
  .bootstrap-select .btn.dropdown-toggle,
  select.multiselect + div.btn-group button.multiselect {
    overflow: hidden; }
  .bootstrap-select .btn.dropdown-toggle::-moz-focus-inner,
  select.multiselect + div.btn-group button.multiselect::-moz-focus-inner {
    margin-left: 10px; } }

/* Multiselect */
.multiselect .glyphicon {
  background: white;
  padding: 18px 12px;
  color: #3b4653;
  border-radius: 0 2px 2px 0;
  position: absolute;
  top: -1px;
  right: -1px;
  margin-left: 15px;
  height: 50px; }

.multiselect + .btn-group {
  display: block; }

.multiselect-container > li.active > a > label {
  font-weight: 600; }

.multiselect {
  padding: 0 0 0 10px; }

select.multiselect + div.btn-group button.multiselect {
  width: 100%;
  text-align: left;
  padding: 17px 30px 6px 12px; }

.multiselect-container label[class='checkbox'] {
  padding: 5px 12px !important; }

.multiselect-container label[class='checkbox'] input {
  margin: 0px 12px 0 0 !important;
  position: relative; }

ul.multiselect-container.dropdown-menu {
  top: 50px;
  min-width: 100%;
  max-height: 440px;
  overflow-y: auto;
  background-color: white; }

button.btn.multiselect {
  overflow: hidden;
  white-space: nowrap; }

.multiselect-container.dropdown-menu > li > a {
  padding: 0px; }

.multiselect-container.dropdown-menu > li > a > label {
  padding: 13px 40px; }

input[type='reset'].btn-white:active .dropdown-menu {
  top: 42px;
  padding: 0;
  border-radius: 2px;
  border-color: #cacaca;
  background-color: #f9f9f9;
  box-shadow: none; }

.dropdown-menu li.NoListItem {
  padding: 8px; }

/* Selectpicker with paging */
.selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0; }
  .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open {
    overflow-x: hidden; }
    .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open.has-pagination {
      padding-bottom: 82px; }
    .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open.global-overlay--element-parent {
      position: absolute; }
    .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open > .bootstrap-select-searchbox {
      padding: 12px; }
      .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open > .bootstrap-select-searchbox .glyphicon {
        right: 28px !important;
        top: 28px !important; }
    .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open .dropdown-menu.inner {
      overflow-y: hidden !important; }
      .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open .dropdown-menu.inner li:last-child {
        border-bottom: 0; }
    .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open form {
      margin: 0; }
      .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open form .pagination {
        margin: 0; }
        .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open form .pagination .pagenum-wrap {
          position: absolute;
          bottom: 12px;
          right: 7px; }
          .selectpicker[data-dynamic-paging="true"] ~ .bootstrap-select .dropdown-menu.open form .pagination .pagenum-wrap:first-child {
            left: 7px;
            right: auto; }

.dropdown-menu > .active > a {
  color: #005177;
  background-color: #e4f7ff;
  border-radius: 2px; }

.dropdown-menu > .active > a:hover {
  background-color: #e4f7ff;
  color: #005177; }

.btn-search.btn-default {
  background-color: transparent;
  outline: 0; }

.bootstrap-select li.root > dt {
  padding-left: 39px !important; }

.bootstrap-select li > a {
  padding-left: 12px; }

#hidablePanel .msisdnSelector li > a {
  padding-left: 15px; }

.bootstrap-select.btn-group .dropdown-menu li > a.opt {
  padding-left: 39px !important; }

#hidablePanel li > a {
  cursor: pointer; }

.select2-container .select2-choices {
  border-radius: 2px;
  background-image: none;
  border: 1px solid #cacaca;
  font-size: 13px;
  padding: 0 8px;
  background-color: #f9f9f9; }

.select2-container .select2-choices .select2-search-field input,
.select2-container .select2-choice,
.select2-container .select2-choices {
  border-radius: inherit;
  color: inherit;
  border-color: #cacaca;
  box-shadow: none;
  background-color: #f9f9f9; }

.select2-container-active.select2-dropdown-open .select2-choice,
.select2-container-multi.select2-container-active.select2-dropdown-open .select2-choices {
  border-bottom: none; }

.select2-container-active .select2-choice,
.select2-container-multi.select2-container-active .select2-choices {
  background-color: white;
  border-color: #66afe9;
  border-width: 2px;
  outline: 0;
  box-shadow: none; }

.select2-drop-active {
  border-color: #66afe9;
  border-width: 2px;
  outline: 0;
  border-top: 0px;
  box-shadow: 0px; }

.select2-search-choice,
.select2-container-multi .select2-choices .select2-search-field input,
.select2-results .select2-result-label {
  font-size: 13px; }

.select2-container-multi .select2-choices .select2-search-choice {
  background: #eee; }

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  font-size: 13px;
  background-color: white;
  color: black;
  padding-left: 7px; }

.bootstrap-select.btn-group .dropdown-menu .no-results {
  padding: 8px 12px;
  background: transparent;
  margin: 0;
  color: #3b4653; }
  .bootstrap-select.btn-group .dropdown-menu .no-results:hover {
    color: #3b4653; }

.bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu,
.btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu {
  border: none;
  padding: 0;
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
  border-width: 0;
  overflow: hidden;
  display: block;
  opacity: 0;
  max-height: 0;
  transition: max-height 300ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: max-height 300ms var(--echt-transition), opacity 300ms var(--echt-transition); }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li > a {
    opacity: 0;
    transform: translateX(30px);
    transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1), opacity 300ms cubic-bezier(0.25, 0.1, 0.25, 1), background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1), color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: transform 300ms var(--echt-transition), opacity 300ms var(--echt-transition), background-color 200ms var(--echt-transition), color 200ms var(--echt-transition); }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li > dt,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li > dt {
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.25, 0.1, 0.25, 1) 0.2s;
    transition: opacity 200ms var(--echt-transition) 0.2s; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(1) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(1) > a {
    transition-delay: 40ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(2) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(2) > a {
    transition-delay: 80ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(3) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(3) > a {
    transition-delay: 120ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(4) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(4) > a {
    transition-delay: 160ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(5) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(5) > a {
    transition-delay: 200ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(6) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(6) > a {
    transition-delay: 240ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(7) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(7) > a {
    transition-delay: 280ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(8) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(8) > a {
    transition-delay: 320ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(9) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(9) > a {
    transition-delay: 360ms; }
  .bootstrap-select.btn-group > .dropdown-toggle ~ .dropdown-menu li:nth-child(10) > a,
  .btn-group > .multiselect.dropdown-toggle ~ .dropdown-menu li:nth-child(10) > a {
    transition-delay: 400ms; }

.bootstrap-select.btn-group.open > .dropdown-toggle + .dropdown-menu,
.btn-group.open > .multiselect.dropdown-toggle ~ .dropdown-menu {
  visibility: visible;
  opacity: 1;
  max-height: 100vh;
  overflow: hidden; }
  .bootstrap-select.btn-group.open > .dropdown-toggle + .dropdown-menu li > a,
  .btn-group.open > .multiselect.dropdown-toggle ~ .dropdown-menu li > a {
    opacity: 1;
    transform: translateX(0px); }
  .bootstrap-select.btn-group.open > .dropdown-toggle + .dropdown-menu li > dt,
  .btn-group.open > .multiselect.dropdown-toggle ~ .dropdown-menu li > dt {
    opacity: 1; }

dt {
  font-weight: 400; }

.table {
  margin-bottom: 0; }
  .table input[type="checkbox"] {
    position: relative; }

.table > thead > tr > th {
  vertical-align: middle;
  border-bottom: none; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle; }

.table-wrapper {
  margin-bottom: 32px !important;
  border: none;
  border-radius: 0;
  box-shadow: none; }

table.table {
  overflow: auto;
  border-collapse: separate;
  color: #3b4653;
  border-radius: 0;
  border-spacing: 0; }

table.table thead th {
  font-weight: 600;
  background: none; }

table.table thead.white {
  background: white; }

table.table thead.left th {
  display: block; }

table.table tbody.right tr {
  display: inline-block; }

table.table tbody.right td {
  display: block; }

table.table > tbody > tr > td,
table.table > thead > tr > th {
  padding: 16px 12px;
  border-top: 1px solid #dadde3;
  border-bottom: none;
  border-right: none; }

table.table > tbody > tr:first-child > td {
  border-top: none; }

table.table td:first-child,
table.table th:first-child {
  border-left: none; }
  @media screen and (min-width: 1200px) {
    table.table td:first-child,
    table.table th:first-child {
      padding-left: 24px; } }

@media screen and (min-width: 1200px) {
  table.table td:last-child,
  table.table th:last-child {
    padding-right: 24px; } }

table.table th {
  color: #5c5c5c;
  text-align: left; }

table.table th.hover {
  cursor: pointer; }

table.table th.number {
  text-align: center; }

table.table th.hover .text {
  float: left; }

table.table th.hover:hover {
  color: #0093d5;
  background: #f0f0f0; }

.sort {
  display: inline-block;
  width: 10px;
  height: 7px;
  cursor: pointer;
  margin-left: 2px; }

.sort-down {
  background: url("../images/sort-down.png") no-repeat; }

.sort-up {
  background: url("../images/sort-up.png") center no-repeat; }

table .hover {
  position: relative; }

table .hover .tooltip {
  font-weight: normal;
  text-align: left;
  display: none;
  opacity: 1;
  border: 1px solid #c8c8c8;
  border-radius: 2px;
  background: #f7f7f7;
  width: 200px;
  position: absolute;
  top: -65px;
  margin-left: 30px;
  padding: 0 0 7px 0;
  border: none; }

table .hover .tooltip.top {
  position: absolute;
  top: -81px;
  right: 10px; }

table .hover {
  position: relative; }

table .hover .tooltip.bottom {
  position: absolute;
  top: 62px;
  right: 10px;
  margin: 0px; }

table .hover .tooltip.top .tooltip-arrow {
  padding-bottom: 1px; }

table .hover .tooltip.bottom.bottom-left {
  position: absolute;
  top: 62px;
  left: 10px;
  margin: 0px; }

table .hover .tooltip.top.top-left {
  position: absolute;
  top: -83px;
  left: 10px;
  margin: 0px;
  padding: 0px; }

table.table-simple {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: separate; }

table.table-simple tr {
  border-bottom: 1px solid #e5e4e3; }

table.table-simple tr td {
  padding: 5px 0;
  width: 50%;
  border-bottom: 1px #eee solid; }
  table.table-simple tr td:first-child {
    padding-right: 6px; }
  table.table-simple tr td:last-child {
    padding-left: 6px; }
  table.table-simple tr td:not(:first-child):not(:last-child) {
    padding-left: 6px;
    padding-right: 6px; }

table.table-simple tr:last-child {
  border-bottom: none; }

table.table-simple td:first-child:nth-last-child(3),
table.table-simple td:first-child:nth-last-child(3) ~ td {
  width: 30%; }

table.table thead tr:first-child > th.checkbox-header {
  background-color: #f2f3f5; }

table.table thead tr:first-child > th.checkbox-header > .tooltip-wrap > .icon {
  font-size: 18px;
  position: absolute;
  top: 70%;
  left: 65%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: #5c5c5c; }

table.table tbody tr > td:first-child {
  vertical-align: middle; }

table.table th > div.question-mark {
  height: 20px;
  margin-right: -10px; }

table.table th > div.question-mark:before {
  top: -4px; }

table.table th > div.question-mark > .tooltip {
  bottom: 30px; }

table.dataTable td.select-checkbox:before {
  border-color: #5c5c5c;
  top: 50%; }

table.dataTable tr.selected td.select-checkbox:before {
  background-color: #419bf9;
  border-color: #419bf9; }

table thead th:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

table thead th:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

table.table thead th.sorting,
table.table thead th.sort-column,
table.table thead th.sorting_disabled {
  box-shadow: none; }

table.table > thead > tr > th.sorting_asc,
table.table > thead > tr > th.sort-asc {
  box-shadow: none;
  color: #0093d5; }

table.table > thead > tr > th.sorting_desc,
table.table > thead > tr > th.sort-desc {
  box-shadow: none;
  color: #0093d5; }

div.sorting-arrows {
  background-color: #f2f3f5;
  border-right: 0;
  width: 20px;
  height: 20px; }

.sorting .smart-table-tab {
  display: inline-block;
  float: none; }

.sorting .sorting-arrows {
  position: relative;
  bottom: -4px;
  display: inline-block;
  float: none; }

.table > thead > tr > th.sorting,
.table > thead > tr > th.sorting_asc,
.table > thead > tr > th.sorting_desc,
.table > thead > tr > th.sorting_disabled {
  white-space: nowrap;
  padding: 14px 12px 15px; }
  .table > thead > tr > th.sorting:first-child:not(.checkbox-header),
  .table > thead > tr > th.sorting_asc:first-child:not(.checkbox-header),
  .table > thead > tr > th.sorting_desc:first-child:not(.checkbox-header),
  .table > thead > tr > th.sorting_disabled:first-child:not(.checkbox-header) {
    padding-left: 24px; }

.sorting_disabled {
  text-align: left !important; }
  .sorting_disabled .smart-table-tab {
    display: inline-block; }
  .sorting_disabled .sorting-arrows {
    display: inline-block; }

th.sorting_desc > div.sorting-arrows,
th.sort_desc > div.sorting-arrows {
  position: relative;
  bottom: -4px;
  display: inline-block;
  background: url("../images/eportal/guidelines/sort-desc.png") no-repeat right 6px center; }

th.sorting > div.sorting-arrows,
th.sort-colum > div.sorting-arrows {
  position: relative;
  bottom: -4px;
  display: inline-block;
  background: url("../images/eportal/guidelines/sort.png") no-repeat right 6px center; }

th.sorting_asc > div.sorting-arrows,
th.sort_asc > div.sorting-arrows {
  position: relative;
  bottom: -4px;
  display: inline-block;
  background: url("../images/eportal/guidelines/sort-asc.png") no-repeat right 6px center; }

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: #ecf4fa; }

.table.dataTable tr.selected td.select-checkbox:after {
  top: 52%;
  left: 46%;
  text-shadow: none;
  color: white; }

table thead th a {
  padding-right: 5px; }

table td.sorting_1 {
  background-color: inherit; }

table thead th a {
  color: black;
  font-weight: 600; }

table thead th a:hover,
table thead th.sorting:hover,
table thead th.sorting_desc:hover,
table thead th.sorting_asc:hover,
table thead th.sort-column:hover a,
table thead th.sort-asc:hover a,
table thead th.sort-desc:hover a {
  color: #0093d5;
  text-decoration: none; }

table thead th.sort-column,
table thead th.sort-asc,
table thead th.sort-desc {
  cursor: pointer; }

table thead th.sort-column:hover,
table thead th.sort-asc:hover,
table thead th.sort-desc:hover,
table thead th.sorting:hover,
table thead th.sorting_asc:hover,
table thead th.sorting_desc:hover {
  cursor: pointer;
  color: #0093d5;
  background-color: #f2f3f5; }

.table.dropdown-table {
  border-spacing: 0;
  border-radius: 0;
  background-color: white; }

.table.dropdown-table > thead > tr > th,
.table.dropdown-table > tbody > tr > th,
.table.dropdown-table > tfoot > tr > th,
.table.dropdown-table > thead > tr > td,
.table.dropdown-table > tbody > tr > td,
.table.dropdown-table > tfoot > tr > td,
.table.dropdown-table td,
.table.dropdown-table th {
  border: none; }

.table.dropdown-table th {
  text-align: left; }

.table.dropdown-table.expandable .slide-trigger {
  cursor: pointer;
  position: relative;
  border-top: 1px solid #d7d7d8; }

.table.dropdown-table:not(.expandable) .slide-trigger {
  cursor: default; }

.table.dropdown-table:not(.expandable) .slide-trigger:hover {
  background-color: white; }

.table.dropdown-table tr td .btn:not(.selectpicker) {
  margin-bottom: 0;
  width: auto;
  display: inline-block; }

.table.dropdown-table .question-mark {
  /* Fix for form validation question-marks inside dropdown table
  display: inline-block;
  position: relative;
  vertical-align: middle;
  */ }

.table.dropdown-table .tooltip.top {
  bottom: 34px;
  right: 0px; }

.table.dropdown-table .detail-label {
  color: #0093d5;
  vertical-align: middle; }

.table.dropdown-table .drop-btn.closed,
.table.dropdown-table .drop-btn.opened {
  display: inline-block;
  margin-left: 10px;
  position: static;
  vertical-align: middle;
  height: 30px; }

.table.dropdown-table:not(.expandable) .drop-btn,
.table.dropdown-table:not(.expandable) .detail-label {
  display: none; }

.dropdown-table_content {
  display: none;
  position: relative;
  padding: 12px 40px; }

.table.dropdown-table td {
  border-left: none;
  border-right: none;
  border-bottom: none; }

.table.dropdown-table .has-content td {
  border: none; }

.table.dropdown-table .slide-trigger {
  cursor: pointer; }

.table.dropdown-table .slide-trigger h6 {
  margin: 0;
  font-weight: 600; }

.table.dropdown-table .slide-trigger.opened {
  background-color: white; }

.table.dropdown-table .has-content > td {
  padding: 0; }

.table.dropdown-table .slide-trigger .icon-fixed-width.icon-large {
  margin-right: 8px;
  padding-right: 0;
  text-align: center; }

.table.dropdown-table .seperated-list > .row {
  border-bottom: 1px solid #b9c4cb; }

.table.dropdown-table .seperated-list > .row:last-child {
  border-bottom: none; }

.table.dropdown-table thead th {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

@media (max-width: 767px) {
  .table.dropdown-table .slide-trigger {
    float: left;
    width: 100%; }
  .table.dropdown-table tr.slide-trigger td {
    height: auto !important; }
  .table.dropdown-table td,
  .table.dropdown-table th {
    width: 100%;
    float: left; }
  .table.dropdown-table tr.slide-trigger > td {
    padding: 0px 18px; }
  .table.dropdown-table tr.slide-trigger > td:first-of-type {
    padding-top: 15px; }
  .table.dropdown-table > tbody > tr > td {
    border-top: none; }
  .table.dropdown-table > tbody > tr.slide-trigger > td:first-of-type {
    border-top: 1px solid #ddd; }
  .table.dropdown-table .slide-trigger span:not(.label) {
    display: inline-block;
    padding-left: 30px; }
  .table.dropdown-table tr.slide-trigger > td.mobile-last-cell {
    padding-bottom: 15px; }
  .dataTable.dropdown-table {
    border: 0; }
  .table.dropdown-table tr.slide-trigger td,
  .table.dropdown-table tr.slide-trigger td:first-of-type,
  .table.dropdown-table th {
    width: 95%;
    float: left;
    box-sizing: content-box;
    padding: 6px 8px; }
  .table.dropdown-table tr.slide-trigger td span:not(.label) {
    padding: 0; }
  .table.dropdown-table tr.slide-trigger td.status-icon .blue {
    padding-left: 10px; }
  .table.dropdown-table > tbody > tr.slide-trigger > td:first-of-type {
    border-top: none; }
  .table.dropdown-table .drop-btn.closed,
  .table.dropdown-table .drop-btn.opened {
    position: absolute;
    top: 8px; }
  .table.dropdown-table .slide-trigger:last-of-type > td:last-of-type,
  .table.dropdown-table tr.has-content:last-of-type > td {
    border-bottom: 1px solid #ddd; }
  .table.dropdown-table .dropdown-table_content {
    padding: 8px; }
  .table.dropdown-table .justify-icon {
    margin-left: 20px; }
  .table.dropdown-table td.dataTables_empty {
    float: none; }
  .table.dropdown-table .slide-trigger {
    padding-top: 7px; }
  .table.dropdown-table .th-empty {
    display: none; } }

@media (min-width: 768px) {
  .table.dropdown-table .slide-trigger {
    background-color: white;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition);
    transition-duration: .2s; }
  .table.dropdown-table .slide-trigger:hover {
    background-color: #e4f7ff; }
  table.table {
    overflow-x: visible;
    overflow-y: visible; }
  .table.dropdown-table .slide-trigger > td {
    border-top: 1px solid #ddd; }
  .table.dropdown-table .slide-trigger:first-child > td {
    border-top: none; }
  .table.dropdown-table .row-justify-sm {
    margin-left: 28px; } }

.invoice-table .status-icon img {
  max-width: 16px;
  margin-top: -2px; }

/*DataTable styling */
.dataTables_filter {
  float: right;
  text-align: right;
  margin-bottom: 10px; }

.dataTables_filter input[type='search'],
.dataTables_filter input.form-control[type='search'] {
  padding: 12px 36px 12px 12px;
  color: #000066;
  background-image: none; }
  .dataTables_filter input[type='search']::-webkit-input-placeholder,
  .dataTables_filter input.form-control[type='search']::-webkit-input-placeholder {
    opacity: 1;
    color: #3b4653; }
  .dataTables_filter input[type='search']:-moz-placeholder,
  .dataTables_filter input.form-control[type='search']:-moz-placeholder {
    opacity: 1;
    color: #3b4653; }
  .dataTables_filter input[type='search']::-moz-placeholder,
  .dataTables_filter input.form-control[type='search']::-moz-placeholder {
    opacity: 1;
    color: #3b4653; }
  .dataTables_filter input[type='search'] .form-control:-ms-input-placeholder,
  .dataTables_filter input.form-control[type='search'] .form-control:-ms-input-placeholder {
    opacity: 1;
    color: #3b4653; }
  .dataTables_filter input[type='search']::-webkit-search-cancel-button,
  .dataTables_filter input.form-control[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button; }

.dataTables_filter > label:after {
  content: '\e91b';
  position: absolute;
  z-index: 50;
  border: none;
  border-radius: 0 2px 2px 0;
  padding: 9px 16px;
  background: #0093d5;
  transition: background-color 0.1s ease-in;
  font-family: o2font;
  font-weight: normal;
  display: inline-block;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 19px;
  color: white;
  right: 0; }

.dataTables_filter input.form-control {
  padding-top: 6px;
  width: 100%;
  padding-right: 40px;
  color: #5c5c5c; }
  .dataTables_filter input.form-control::-webkit-input-placeholder {
    color: #bbbbbb;
    opacity: 1; }
  .dataTables_filter input.form-control::-moz-placeholder {
    color: #bbbbbb;
    opacity: 1; }
  .dataTables_filter input.form-control:-moz-placeholder {
    /* Older versions of Firefox */
    color: #bbbbbb;
    opacity: 1; }
  .dataTables_filter input.form-control:-ms-input-placeholder {
    color: #bbbbbb;
    opacity: 1; }

.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1; }
  .dataTables_wrapper table + .row {
    margin: 0; }

.datatables_toolbar {
  float: left;
  font-size: 13px; }

.datatables_toolbar .bootstrap-select.btn-group .btn .filter-option {
  height: 25px; }

.datatables_toolbar .btn-group.bootstrap-select {
  margin: 0; }

.datatables_toolbar .selector {
  min-width: 150px;
  float: none;
  display: inline-block;
  padding: 0 10px 0 0; }

.datatables_toolbar .info-row {
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block; }

.datatables_toolbar .found_items {
  display: inline-block;
  position: relative;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .datatables_toolbar .found_items {
      padding-left: 20px; } }

.datatables_toolbar .btn.remove-icon {
  height: 44px;
  position: absolute;
  right: -20px;
  top: -10px;
  min-width: 44px;
  height: 44px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
  padding: 0; }

.datatables_toolbar .remove-icon:before {
  content: '\e9e9';
  height: 16px;
  width: 16px;
  display: inline-block;
  font-family: 'o2font';
  font-size: 24px;
  position: relative;
  top: 2px;
  color: #3b4653; }

.datatables_toolbar .selected_items {
  display: inline-block;
  padding-left: 20px; }

.dataTables_length {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .dataTables_length {
      text-align: right; } }
  .dataTables_length .form-group {
    position: relative; }

.dataTables_length select {
  padding-top: 6px; }

.datatables_toolbar .selected_items .btn.action {
  min-width: 0;
  color: #0093d5;
  background: url("../images/eportal/guidelines/dropdown-box-down-btn-sm.png") no-repeat 37px 6px;
  padding: 0 20px 0 0;
  font-size: 13px;
  border: none;
  vertical-align: middle;
  margin-top: -4px;
  line-height: 1.4; }

.datatables_toolbar .bootstrap-select .selectpicker.btn {
  padding: 20px 12px 3px 12px; }

.dataTable,
.table-draggable.dataTable.no-footer {
  border: 0px solid #b6c5cd;
  border-spacing: 0; }

table.table-draggable > tbody > td,
table.table-draggable > thead > th {
  border-left: none; }

table.table thead th,
table.table thead.grey th {
  background-color: #f2f3f5; }

table.DTCR_clonedTable.dataTable {
  position: absolute !important;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202; }

.datatables_filter-wrapper {
  overflow: hidden; }

.table tr td .btn:not(.selectpicker) {
  width: 95%;
  margin-bottom: 9px; }

.table tr td .btn-left,
.table tr td .btn-right,
.table tr td .popup .btn {
  width: inherit; }

/************ responsive table *****************/
.fixed-table {
  position: absolute;
  z-index: 200;
  width: auto;
  border-right: 1px solid #ddd;
  background-color: white;
  border-radius: 0px !important; }

.fixed-table-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden; }
  .fixed-table-wrapper .table-wrapper {
    overflow-x: auto; }
  .fixed-table-wrapper .table-fixed-icon {
    display: none !important; }
  .fixed-table-wrapper > table {
    box-shadow: 2px 0px 15px rgba(60, 60, 60, 0.25); }
    .fixed-table-wrapper > table tr > td {
      padding-right: 24px !important; }

.fixed-table-wrapper.rounded {
  border: 0;
  border-radius: 2px;
  box-shadow: none; }

.table-responsive {
  position: relative;
  overflow-x: inherit; }

.fixed-table-wrapper > .table-responsive {
  overflow-y: hidden;
  margin-bottom: 0px; }

.fixed-table-wrapper > .table-responsive {
  border: 0px;
  margin-bottom: 0px; }

.fixed-table-wrapper > .fixed-table {
  left: 0px;
  top: 0px; }

.fixed-table-wrapper.rounded > .fixed-table {
  left: 0px; }

.table-fixed-icon {
  z-index: 250;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0px;
  pointer-events: none; }

.table-fixed-icon.left {
  left: 0px;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1); }

.table-fixed-icon.right {
  right: 0px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffffff', GradientType=1); }

/*******************************/
table.table.horizontal th {
  border: none;
  border-top: 1px solid;
  border-color: rgba(217, 219, 226, 0.7);
  background: #f2f3f5;
  color: #0c0c0c;
  font-weight: 600;
  margin: 0;
  padding-left: 12px;
  padding-right: 12px; }
  @media screen and (min-width: 1200px) {
    table.table.horizontal th {
      padding-left: 24px;
      padding-right: 24px; } }

@media (min-width: 992px) {
  table.table tbody tr {
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition);
    transition-duration: 0.2s; }
  table.table tbody tr:not(.has-content):hover {
    background-color: #e4f7ff; } }

table.table.horizontal tr > td,
table.table.horizontal tr > th {
  padding: 16px 24px; }

table.table.horizontal tr:first-child td,
table.table.horizontal tr:first-child th {
  border-bottom: none;
  border-top: none; }

table.table.horizontal tr td {
  border-left: 1px solid #ececec;
  border-color: #d9dbe2; }

table.table.horizontal tr th + td {
  border-left: 0; }

.table-full thead {
  height: 100px; }

.table-full tr th {
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
  text-align: center;
  background: none; }

@media screen and (max-width: 767px) {
  .table-responsive {
    border: 0; } }

.table-full tr.no-borders th {
  border: 0;
  border-spacing: 0; }

.table-full tr th h5 {
  margin-bottom: 18px; }

.table-full tr th:first-child {
  border-left: none; }

.table-full tr th:last-child {
  border-right: none; }

.table-full tr td {
  border: 1px solid #ececec;
  border-right: none;
  padding: 16px; }

.table-full tr td:first-child {
  text-align: left;
  border-left: none; }

.table-full tr td .btn {
  width: 95%;
  margin-bottom: 9px; }

.table-full td.favorite {
  background: #f3faff;
  border-left: 2px solid #65b4e4;
  border-right: 2px solid #65b4e4; }

.table-full td.favorite.check-mark {
  background: #f3faff url(../images/check-mark.png) center no-repeat; }

.table-full tr > th.favorite {
  background: #f3faff;
  border: 2px solid #65b4e4;
  border-bottom: none; }

.favorite-sign {
  background: #65b4e4;
  width: 147px;
  height: 35px;
  border: 1px solid #378dc1;
  border-radius: 20px;
  margin: auto; }

.favorite-sign p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 3px 0; }

.favorite-arrow {
  top: -1px;
  height: 17px;
  background: url("../images/favorites-arrow.png") center no-repeat;
  position: relative; }

.sim {
  width: 125px;
  height: 119px;
  margin: 0 auto;
  background: url(../images/sim.png) center 12px no-repeat; }

.sim.sim-blue {
  background-position: 18px 0; }

.sim-silver {
  background-position: center -119px; }

.sim-gold {
  background-position: center -238px; }

.sim-platinum {
  background-position: center -357px; }

.sim-diamond {
  background-position: center -476px; }

.table-full .last td {
  border-bottom: none; }

.table-full .last td.favorite {
  border-bottom: 2px solid #65b4e4; }

.table-full tr:first-child td {
  border-top: none; }

/***********************************
Smart table search menu mobile version
****************************************/
@media (max-width: 767px) {
  div.datatables_toolbar div input {
    float: left !important;
    margin-right: 10px;
    margin-bottom: 10px; }
  .found_items {
    float: left;
    margin-bottom: 10px; }
  .selected_items {
    float: left;
    margin-bottom: 10px; }
  .datatables_toolbar > div > .selected_items {
    padding-left: 0px !important; }
  .datatables_toolbar > div > .found_items {
    padding-left: 0px !important;
    margin-right: 10px; }
  .dataTables_filter {
    position: relative;
    width: 100%; }
    .dataTables_filter label {
      width: 100%; }
      .dataTables_filter label input {
        padding-right: 62px !important; }
      .dataTables_filter label::after {
        top: 0; } }

table .table,
.table-draggable,
.dataTable,
.no-footer {
  overflow: visible !important; }

.product-specification-table .table tr th {
  text-align: left; }

.product-specification-table .table tr th,
.product-specification-table .table tr td {
  padding: 6px 8px !important; }

.product-specification-table td div {
  line-height: 28px; }

.datatables_filter-wrapper {
  overflow: hidden; }

td.center,
th.center {
  text-align: center; }

th.sort,
th.sort-asc,
th.sort-desc {
  padding-right: 23px;
  background: #d2e9ff url(../images/eportal/table-sortable.gif) no-repeat right center; }

th.sort-asc,
th.sort-desc {
  background-color: #b3d9ff; }

th.sort-asc {
  background-image: url(../images/eportal/table-sort-asc.gif); }

th.sort-desc {
  background-image: url(../images/eportal/table-sort-desc.gif); }

th a {
  display: block; }

tr.odd td {
  height: 35px !important; }

tr.even td {
  height: 35px !important; }

.ui-datepicker td {
  padding: 0;
  height: auto; }

.t-thead {
  background-color: #f2f4f5;
  padding: 12px 14px 13px;
  border-radius: 2px; }

.t-thead h6 {
  margin-bottom: 0; }

.t-row {
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 16px; }

.t-row:hover {
  background-color: #e4f7ff; }

.t-row:last-of-type {
  border-bottom: none; }

.t-row h6,
.t-row span {
  display: block;
  float: left;
  margin-right: 120px; }

.t-row span {
  margin-top: 8px; }

.t-cell {
  padding: 30px 20px; }

.summary-row {
  border-bottom: 1px solid #d9dbe2;
  padding: 8px 0; }
  .summary-row > span.pull-left {
    color: #3b4653; }
  .summary-row > span.pull-right {
    color: #343434; }

.link-doc {
  display: block !important;
  margin: 6px 0; }
  .link-doc:first-of-type {
    margin-top: 18px; }

.checkbox-header .tooltip-wrap {
  width: 100%;
  height: 20px; }

.checkbox-header .tooltip-wrap .tooltip {
  display: none;
  opacity: 1;
  z-index: 5000;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
  top: 35px;
  left: 4px;
  border-radius: 2px;
  margin-top: -6px;
  bottom: auto; }

.checkbox-header .tooltip-wrap .tooltip .check_row a {
  white-space: nowrap;
  text-align: left;
  color: #0093d5;
  padding: 10px;
  border-bottom: solid 1px rgba(82, 109, 119, 0.1);
  margin: 0;
  font-weight: normal; }

.checkbox-header .tooltip-wrap .tooltip.top, .checkbox-header .tooltip-wrap .tooltip.bottom {
  padding: 0; }

.checkbox-header .tooltip-wrap .tooltip .check_row {
  position: relative;
  font-size: 14px; }

.checkbox-header .tooltip-wrap .tooltip .check_row > a > span.o2f-settings ~ span.text {
  padding-left: 25px; }

.checkbox-header .tooltip-wrap .tooltip .check_row a > .icon {
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translate(-50%, -50%);
  color: #0093d5; }

.checkbox-header .tooltip-wrap .tooltip .check_row:last-child a {
  border: none; }

.checkbox-header .tooltip .arrow {
  display: none; }

@media (max-width: 767px) {
  .checkbox-header .tooltip .arrow {
    margin: -6px 0 0 6px; } }

@media (min-width: 768px) and (max-width: 978px) {
  .checkbox-header .tooltip .arrow {
    margin: -6px 0 0 8.5px; } }

@media (min-width: 979px) {
  .checkbox-header .tooltip .arrow {
    margin: -6px 0 0 10px; } }

.dataTables_length .control-label {
  pointer-events: none; }

/*****************************
* UI autocomplete
*****************************/
.ui-autocomplete-input.focus.ui-autocomplete-loading,
.ui-autocomplete-input.focus {
  border-radius: 2px;
  border-color: #66afe9 !important;
  border-width: 2px;
  outline: 0;
  box-shadow: none; }

.ui-autocomplete {
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-color: #66afe9;
  border-width: 2px;
  outline: 0;
  box-shadow: none;
  border-top: none;
  padding-top: 6px;
  margin-top: -6px; }

.ui-autocomplete-input.autocomplete-opened,
.ui-autocomplete-input.focus.ui-autocomplete-loading.autocomplete-opened {
  border-bottom: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.ui-autocomplete .ui-menu-item .ui-corner-all {
  font-size: 14px;
  color: #3b4653; }

.ui-autocomplete .ui-menu-item .ui-corner-all.ui-state-focus,
.ui-autocomplete .ui-menu-item .ui-corner-all.ui-state-hover {
  border-radius: 0;
  color: white;
  background-color: #428bca;
  border: none;
  margin: 0px; }

.ui-autocomplete-close {
  text-align: right;
  cursor: pointer;
  background-color: white;
  color: #0086c3;
  width: auto;
  font-weight: normal;
  margin-right: 6px;
  margin-bottom: 4px; }

.ui-autocomplete-close.hover {
  cursor: pointer;
  background-color: white;
  color: #0086c3;
  font-weight: normal; }

.ui-autocomplete .ui-menu-item a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.ui-autocomplete-input {
  background-size: 5%;
  background-position: 95% center;
  background-repeat: no-repeat; }

.ui-autocomplete-loading,
.ui-autocomplete-loading.focus {
  background-image: url("../images/eportal/guidelines/ajax-loader.gif") !important; }

/* autocomplete typeahead start*/
.tt-hint,
.twitter-typeahead {
  width: 100%; }

.tt-hint {
  background-color: white;
  border: 1px solid #cacaca;
  box-shadow: none;
  padding: 18px 12px 6px 12px;
  color: #999; }

.tt-dropdown-menu {
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 2px solid #2792D9;
  outline: 0;
  box-shadow: none;
  border-top: none;
  padding-top: 6px;
  margin-top: -6px;
  overflow-y: auto;
  max-height: 258px;
  border-top: 0px; }

.tt-suggestion p {
  padding: 12px .4em;
  margin: 0px; }

.tt-suggestion.tt-cursor {
  cursor: pointer;
  border-radius: 0;
  color: black;
  background-color: #f5f5f5;
  border: none;
  margin: 0px; }

.tt-suggestion.tt-cursor p {
  text-decoration: none;
  padding: 12px .4em;
  min-height: 0; }

/* autocomplete typeahead end*/
/* Pagination */
.pagination {
  text-align: center;
  display: block; }
  .pagination.pagination-hidden {
    display: none; }
  .pagination .pagenum-wrap {
    display: inline-block;
    margin-right: 5px;
    margin-left: 5px; }
  .pagination .page-wrap {
    display: inline-block;
    vertical-align: top;
    position: relative;
    top: 0;
    max-width: 95px; }
    .pagination .page-wrap.current {
      height: 22px;
      margin-left: 5px;
      margin-right: 5px;
      text-decoration: underline; }
    .pagination .page-wrap .btn > .filter-option {
      text-align: center; }
    .pagination .page-wrap input {
      width: 50px;
      border-radius: 2px;
      border: 1px solid #d9dbe2;
      height: 50px;
      text-align: center;
      font-size: 14px; }
  .pagination .fa {
    font-family: 'o2font' !important;
    display: inline-block; }
    .pagination .fa:before {
      top: 12px;
      left: 12px;
      position: absolute;
      font-weight: normal; }
    .pagination .fa.fa-angle-right:before {
      content: '\e99c'; }
    .pagination .fa.fa-angle-left:before {
      content: '\e99e'; }

.pagination > .pagenum-wrap:first-of-type,
.pagination > .pagenum-wrap:last-of-type,
.pagination > .pagenum-wrap:first-of-type:hover,
.pagination > .pagenum-wrap:last-of-type:hover {
  width: 50px;
  height: 50px; }

.pagination .input-group.page-wrap + span.text {
  margin-left: 12px; }

.pagination .text {
  height: 50px;
  display: inline-block;
  line-height: 50px;
  vertical-align: top;
  padding-right: 3px; }

.pagination .prev,
.pagination .next {
  border-radius: 2px;
  position: relative;
  border: 1px solid #0093d5;
  height: 100%;
  width: 100%;
  line-height: 28px;
  letter-spacing: 1px;
  text-decoration: none;
  font-size: 24px; }

.pagination .prev[class^='o2f-']:before,
.pagination .prev[class*=' o2f-']:before,
.pagination .next[class^='o2f-']:before,
.pagination .next[class*=' o2f-']:before {
  top: 14px;
  font-size: 22px;
  margin-right: 0; }

.pagination .prev:hover,
.pagination .next:hover {
  background-color: #e4f7ff; }

.pagination .prev:focus,
.pagination .next:focus,
.pagination .prev:active,
.pagination .next:active {
  background-color: #99cce6; }

.pagination > .disabled > a:before,
.pagination > .disabled > a:hover:before,
.pagination > .disabled > a:focus:before {
  color: #bfbfbf; }

.pagination .text.all {
  display: inline-block; }

.pagination .text.current {
  display: inline-block;
  font-weight: bold; }

.pagination > .pagination-summary {
  margin-left: 7px;
  margin-right: 7px; }

.pagination > .pagination-summary .pagination-form {
  display: inline; }

.pagination.select .next {
  margin-left: 5px; }

.pagination .pagenum-wrap:not(.disabled) a:hover {
  cursor: pointer; }

.pagination .question-mark {
  display: none !important; }

.pagenum-wrap.disabled a:hover {
  text-decoration: none; }

.label {
  font-size: 13px;
  font-weight: 600;
  padding: 4px 6px 5px;
  color: white;
  display: inline-block;
  background: #b5b7bf; }
  .label .tooltip-dots {
    line-height: 1 !important;
    border-color: white !important; }
  .label .tooltip {
    bottom: 24px !important;
    right: 0; }

.label-default {
  background: #b5b7bf; }

.label-primary {
  background: #0093d5; }

.label-success {
  background: #3bb237; }

.label-info {
  background: #00b9b5; }

.label-warning {
  background: #ff8b2f; }

.label-danger {
  background: #e82b3b; }

a > .label:hover.label-default {
  background: #a7aab3; }

a > .label:hover.label-primary {
  background: #0081bc; }

a > .label:hover.label-success {
  background: #359f31; }

a > .label:hover.label-info {
  background: #00a09c; }

a > .label:hover.label-warning {
  background: #ff7d16; }

a > .label:hover.label-danger {
  background: #e11829; }

.box-block {
  display: inline-block; }

.box {
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 2px; }
  .box.box-border {
    border: 1px solid #d9dbe2;
    background: white; }
    .box.box-border.box-icon {
      padding-bottom: 12px; }
  .box.box-grad {
    background: #f7f7f9; }
  .box.box-shadow {
    box-shadow: 0px 4px 10px rgba(0, 44, 90, 0.1);
    background: white; }
  .box.box-gray {
    background-color: #f6f6f6;
    border: 1px solid #d7d7d8;
    padding-left: 10px;
    padding-right: 10px; }
  .box .box-content {
    _float: left; }
    .box .box-content > p {
      margin-top: 10px; }
  .box .box-pic {
    text-align: right;
    padding-top: 12px; }

.directory .box-grad:hover {
  background: none;
  -webkit-filter: none;
          filter: none; }

.box-no-border {
  padding: 5px 0 8px; }

.box-icon > p {
  margin: 6px 0 0; }

.box-icon i {
  font-size: 18px;
  margin-right: 6px; }

.box-icon .more {
  margin-left: 24px; }

.box-message {
  display: inline-block; }

.box-table {
  padding: 0; }

.box-date h4 {
  margin: 6px 0 24px; }

.box.box-has-date.box-border .box-date {
  padding: 15px; }

@media (min-width: 767px) {
  .box-date {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 222px; }
  .box-table.box-has-date {
    padding-right: 222px;
    position: relative; } }

.box-table .box-table-head {
  background-color: #f2f4f5;
  padding: 8px 14px; }

.box-table {
  padding: 0; }

@media (min-width: 767px) {
  .box-table.box-has-date .box-date {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 235px; }
  .box-table.box-has-date {
    min-height: 215px;
    padding-right: 235px;
    position: relative; }
  .box-table.box-has-date .box-date .t-cell {
    overflow-y: auto;
    border-left: 1px solid #dcdcdc; }
  .box-table.box-has-date .box-date .t-thead {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px; }
  .box-table.box-has-date > .t-thead {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px; } }

.login-box i {
  font-size: 36px;
  color: #0093d5;
  margin-bottom: 12px;
  display: block; }

.form-group {
  position: relative; }

input::-webkit-input-placeholder {
  color: #bbbbbb; }

input::-moz-placeholder {
  color: #bbbbbb; }

input:-moz-placeholder {
  /* Older versions of Firefox */
  color: #bbbbbb; }

input:-ms-input-placeholder {
  color: #bbbbbb; }

.form-control::-webkit-input-placeholder {
  opacity: 0;
  transition: .15s;
  color: #bbbbbb; }

.form-control:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
  transition: .15s; }

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
  transition: .15s; }

.form-control:-ms-input-placeholder {
  opacity: 1;
  color: white;
  transition: .15s; }

.form-control:focus::-webkit-input-placeholder {
  opacity: 1; }

.form-control:focus:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1; }

.form-control:focus::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1; }

.form-control:focus:-ms-input-placeholder {
  color: #bbbbbb;
  opacity: 1; }

input[type='button'].btn,
input[type='submit'].btn,
input[type='reset'].btn {
  min-width: 70px;
  padding: 12px 23px; }

.progress-button input[type='button'].btn {
  max-width: 180px; }

.btn-search-cancel {
  display: none; }

/* FORMS - Bootstrap overrides */
form .form-group {
  margin-bottom: 12px;
  position: relative; }
  @media screen and (min-width: 1200px) {
    form .form-group {
      margin-bottom: 24px; } }

form .btn, .form-group .btn {
  padding: 11px 18px 12px;
  font-size: 15px;
  border-radius: 2px;
  margin-bottom: 0; }

.input-group-addon {
  padding: 6px 10px;
  min-width: 50px;
  background: white; }

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  margin-left: 0px;
  margin-top: 3px;
  left: 0px;
  position: absolute;
  width: auto;
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 0.2s;
  transition-property: all; }
  input[type="radio"]:after,
  .radio input[type="radio"]:after,
  .radio-inline input[type="radio"]:after {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    position: absolute;
    top: 2px;
    left: 2px;
    background: #0093d5;
    opacity: 0; }
  input[type="radio"]:checked:after,
  .radio input[type="radio"]:checked:after,
  .radio-inline input[type="radio"]:checked:after {
    opacity: 1;
    -webkit-animation: radio-do-check 0.15s;
            animation: radio-do-check 0.15s; }
  input[type="radio"]:not(:checked):after,
  .radio input[type="radio"]:not(:checked):after,
  .radio-inline input[type="radio"]:not(:checked):after {
    -webkit-animation: radio-do-uncheck 0.15s;
            animation: radio-do-uncheck 0.15s; }
  input[type="radio"].radio-lg,
  .radio input[type="radio"].radio-lg,
  .radio-inline input[type="radio"].radio-lg {
    padding: 9px; }
    input[type="radio"].radio-lg:after,
    .radio input[type="radio"].radio-lg:after,
    .radio-inline input[type="radio"].radio-lg:after {
      top: 3px;
      left: 3px; }
  input[type="radio"]:checked,
  .radio input[type="radio"]:checked,
  .radio-inline input[type="radio"]:checked {
    background-color: white;
    border-color: #0093d5; }
  input[type="radio"]:active, input[type="radio"]:focus, input[type="radio"]:checked:active:focus,
  .radio input[type="radio"]:active,
  .radio input[type="radio"]:focus,
  .radio input[type="radio"]:checked:active:focus,
  .radio-inline input[type="radio"]:active,
  .radio-inline input[type="radio"]:focus,
  .radio-inline input[type="radio"]:checked:active:focus {
    outline: none; }

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  -webkit-appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 2px;
  display: inline-block;
  margin-left: 0;
  margin-top: 0;
  left: 0px;
  top: 4px;
  position: absolute;
  width: auto;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 0.2s; }
  input[type="checkbox"]:active, input[type="checkbox"]:focus, input[type="checkbox"]:checked:active:focus,
  .checkbox input[type="checkbox"]:active,
  .checkbox input[type="checkbox"]:focus,
  .checkbox input[type="checkbox"]:checked:active:focus,
  .checkbox-inline input[type="checkbox"]:active,
  .checkbox-inline input[type="checkbox"]:focus,
  .checkbox-inline input[type="checkbox"]:checked:active:focus {
    outline: none; }
  input[type="checkbox"]:before,
  .checkbox input[type="checkbox"]:before,
  .checkbox-inline input[type="checkbox"]:before {
    width: 100%;
    height: 100%;
    background: white;
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0px;
    opacity: 0;
    border-radius: 2px; }
  input[type="checkbox"]:after,
  .checkbox input[type="checkbox"]:after,
  .checkbox-inline input[type="checkbox"]:after {
    font-family: o2font;
    content: '\e9ab';
    font-size: 19px;
    position: absolute;
    top: 0px;
    left: -1px;
    color: white;
    line-height: 1;
    opacity: 0; }
  input[type="checkbox"]:checked,
  .checkbox input[type="checkbox"]:checked,
  .checkbox-inline input[type="checkbox"]:checked {
    background-color: #0093d5;
    border: 1px solid #0093d5;
    color: white; }
    input[type="checkbox"]:checked:before,
    .checkbox input[type="checkbox"]:checked:before,
    .checkbox-inline input[type="checkbox"]:checked:before {
      -webkit-animation: checkbox-do-check 0.15s;
              animation: checkbox-do-check 0.15s; }
    input[type="checkbox"]:checked:after,
    .checkbox input[type="checkbox"]:checked:after,
    .checkbox-inline input[type="checkbox"]:checked:after {
      opacity: 1; }
  input[type="checkbox"]:not(:checked),
  .checkbox input[type="checkbox"]:not(:checked),
  .checkbox-inline input[type="checkbox"]:not(:checked) {
    -webkit-animation: checkbox-do-uncheck-bg 0.15s;
            animation: checkbox-do-uncheck-bg 0.15s; }
    input[type="checkbox"]:not(:checked):before,
    .checkbox input[type="checkbox"]:not(:checked):before,
    .checkbox-inline input[type="checkbox"]:not(:checked):before {
      -webkit-animation: checkbox-do-uncheck-tick 0.15s;
              animation: checkbox-do-uncheck-tick 0.15s; }
  input[type="checkbox"].checkbox-lg,
  .checkbox input[type="checkbox"].checkbox-lg,
  .checkbox-inline input[type="checkbox"].checkbox-lg {
    padding: 9px; }
    input[type="checkbox"].checkbox-lg:checked:after,
    .checkbox input[type="checkbox"].checkbox-lg:checked:after,
    .checkbox-inline input[type="checkbox"].checkbox-lg:checked:after {
      top: -1px;
      left: 0px; }

.checkbox.checkbox-clean input[type="checkbox"] {
  left: 0; }

.checkbox.checkbox-clean label {
  padding-left: 18px; }

.radio label,
.checkbox label {
  padding-left: 30px;
  color: #343434; }

.radio .question-mark,
.checkbox .question-mark {
  height: 20px;
  width: 20px;
  position: relative;
  margin-left: 10px; }

.radio .question-mark:before,
.checkbox .question-mark:before {
  top: 4px;
  left: 0px;
  line-height: 1; }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0px; }

form {
  margin: 20px auto; }

form#hrefFm {
  margin: 0; }

.required {
  margin-left: 10px; }

.steps-wrapper {
  display: flex;
  flex-direction: row;
  margin: 48px 0px; }

.steps-wrapper > div.active:not(.current) a span {
  color: rgba(51, 153, 204, 0.65); }

.steps-wrapper > div.active:not(.current) a:hover span {
  color: #3399cc; }

.steps-wrapper > .disabled a {
  pointer-events: none; }

.steps-wrapper > .inactive a {
  cursor: not-allowed; }

.steps-wrapper > .current a {
  pointer-events: none; }

.step-line {
  height: 3px;
  flex-grow: 1;
  position: relative; }

.step-circle {
  width: 18px;
  height: 18px;
  background-color: white;
  position: absolute;
  top: -8px;
  right: -9px;
  z-index: 2; }

.step-circle:before,
.step-circle:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%; }

.step-circle:before {
  width: 13px;
  height: 13px; }

.step-circle:after {
  width: 5px;
  height: 5px;
  background-color: white; }

.step-line,
.step-circle:before {
  background-color: #e6e7eb; }

.step-line.active,
.step-line.active .step-circle:before {
  background-color: #2990cb;
  cursor: auto; }

.step-line.active.has-link,
.step-line.active.has-link .step-circle:before {
  cursor: pointer; }

.step-circle span {
  position: absolute;
  width: 150px;
  bottom: -17px;
  left: 9px;
  text-align: center;
  transform: translate(-50%, 50%);
  font-weight: 600;
  color: black;
  opacity: 0.5; }

.step-line.active .step-circle span {
  opacity: 1; }

.form-steps-wrapper .line.blue {
  border-bottom: 1.6px solid #2d9ed8; }

.form-steps-wrapper .line.gray {
  border-bottom: 1.6px solid #b7c5cc; }

.form-steps-wrapper .line.line-gradient {
  height: 0px;
  background: none;
  border-bottom: 1.6px solid;
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* FF3.6+ */
  /* Opera 11.10+ */
  -o-border-image: linear-gradient(to right, #319fd8 0%, #b5c4cc 50%, #b5c4cc 100%);
     border-image: linear-gradient(to right, #319fd8 0%, #b5c4cc 50%, #b5c4cc 100%);
  /* W3C */
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-image-slice: 1; }

.short-form .form-group > div {
  padding-left: 0;
  padding-right: 0; }

.form-horizontal label.control-label,
select.selectpicker ~ label.control-label,
input[type=text].filled ~ label.control-label,
input[type=email].filled ~ label.control-label,
input[type=password].filled ~ label.control-label,
input[type=tel].filled ~ label.control-label,
input[type=url].filled ~ label.control-label,
input[type=text]:focus ~ label.control-label,
input[type=email]:focus ~ label.control-label,
input[type=tel]:focus ~ label.control-label,
input[type=url]:focus ~ label.control-label,
input[type=password]:focus ~ label.control-label,
textarea.form-control:focus ~ label.control-label,
textarea.form-control.filled ~ label.control-label {
  font-size: 12px;
  color: #999;
  padding: 0px;
  position: absolute;
  top: 5px;
  left: 12px;
  z-index: 3; }

textarea.form-control:focus ~ .question-mark,
textarea.form-control.filled ~ .question-mark {
  display: none; }

label.control-label.label-expanded,
input[type=text] ~ label.control-label,
input[type=email] ~ label.control-label,
input[type=tel] ~ label.control-label,
input[type=url] ~ label.control-label,
input[type=password] ~ label.control-label,
textarea.form-control ~ label.control-label {
  font-size: 14px;
  color: #343434;
  position: absolute;
  padding: 13px 12px 14px;
  text-align: left;
  transition: all 0.15s ease;
  cursor: text;
  top: 0;
  left: 0px;
  width: calc(100% - 56px);
  white-space: nowrap;
  overflow: hidden; }

label.error-label.red:before {
  top: 0;
  position: absolute;
  content: ' ';
  display: block;
  left: -15px;
  width: 15px;
  height: 100%;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffffff', GradientType=1);
  /* IE6-9 */ }

textarea.form-control ~ label.control-label {
  width: auto; }

textarea.form-control:focus ~ label.control-label,
textarea.form-control.filled ~ label.control-label {
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.9) 90%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0); }

textarea.form-control:focus ~ label.control-label {
  border-color: #2792d9; }

.row > .form-group > div[class*='col-'] > label.control-label,
.row > .form-group > div[class*='col-'] > .form-group > label.control-label {
  margin-left: 6px; }
  @media screen and (min-width: 1200px) {
    .row > .form-group > div[class*='col-'] > label.control-label,
    .row > .form-group > div[class*='col-'] > .form-group > label.control-label {
      margin-left: 12px; } }

.row > .form-group > div[class*='col-'] > label.error-label,
.row > .form-group > div[class*='col-'] > .form-group > label.error-label {
  right: 18px; }
  @media screen and (min-width: 1200px) {
    .row > .form-group > div[class*='col-'] > label.error-label,
    .row > .form-group > div[class*='col-'] > .form-group > label.error-label {
      right: 24px; } }

.row > .form-group > div[class*='col-'] > .question-mark.question-mark-input,
.row > .form-group > div[class*='col-'] > .form-group > .question-mark.question-mark-input {
  right: 7px; }

div[class*='col-'] > label.control-label.label-fixed {
  margin-left: 12px !important; }

label.control-label.label-fixed {
  position: static; }

label.control-label.label-readonly {
  position: static;
  margin-left: 12px !important; }

.form-horizontal .control-label.label-prepend {
  margin-left: 45px; }

.form-horizontal .control-label.label-prepend,
input[type=text].appended ~ label.control-label,
input[type=email].appended ~ label.control-label,
input[type=tel].appended ~ label.control-label,
input[type=url].appended ~ label.control-label,
input[type=password].appended ~ label.control-label,
textarea.form-control.appended ~ label.control-label {
  width: calc(100% - 100px); }

.form-control {
  padding: 24px 12px 10px 12px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  transition: all 0.15s ease;
  height: 50px;
  color: #343434; }
  .form-control.prepended {
    border-left: 0; }
  .form-control.appended {
    border-right: 0; }

.form-control:focus,
.form-control.focus {
  border: 1px solid;
  border-color: #0093d5;
  box-shadow: 0px 0px 4px rgba(0, 147, 213, 0.5); }

.form-control-static {
  color: #343434;
  padding: 0px 0px 0px 12px;
  min-height: 24px;
  margin-top: -5px; }

.form-control.has-tooltip {
  padding-right: 50px; }

.form-control.has-tooltip[data-hint]:not([data-hint=""]) .question-mark, .form-control.has-tooltip[data-hint]:not([data-hint=""]) ~ .questionmark {
  opacity: 1;
  visibility: visible; }

.has-tooltip .question-mark {
  z-index: 99; }

.has-tooltip + .question-mark {
  z-index: 99; }

.has-tooltip ~ .question-mark > .tooltip {
  display: block;
  opacity: 0;
  visibility: hidden; }

.has-tooltip ~ .question-mark:hover > .tooltip {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto; }

.has-tooltip.tooltip-visible:focus ~ .question-mark > .tooltip {
  display: block;
  opacity: 1; }
  .has-tooltip.tooltip-visible:focus ~ .question-mark > .tooltip > .tooltip-inner,
  .has-tooltip.tooltip-visible:focus ~ .question-mark > .tooltip .tooltip-arrow {
    display: block; }

/*
.has-success,
input.form-control.has-success {
border-color: #22b733;
-webkit-box-shadow: inset 0px 0px 0px 1px #22b733;
-moz-box-shadow:    inset 0px 0px 0px 1px #22b733;
box-shadow:         inset 0px 0px 0px 1px #22b733;
}
*/
@-moz-document url-prefix() {
  .bootstrap-select.has-error .selectpicker:not(ul) .filter-option {
    padding-left: 18px; } }

select.has-error + .bootstrap-select button {
  border-color: #e75555 !important;
  box-shadow: 0 0 0 1px #e75555 inset !important; }

.has-error.bootstrap-select {
  background: inherit;
  border-color: inherit;
  box-shadow: none;
  outline: none;
  padding-left: 0px; }

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0; }

.has-error,
.form-control.has-error:focus {
  border: 1px solid;
  border-color: #e82b3b;
  box-shadow: 0px 0px 4px rgba(232, 43, 59, 0.5);
  color: #e82b3b; }

label.error-label.red {
  position: absolute;
  right: 12px;
  top: 2px;
  background: white;
  padding: 12px 0 11px 6px;
  border-left: 1px solid white;
  margin-bottom: 0;
  z-index: 3;
  -webkit-animation: slide-input-required-label 0.3s;
          animation: slide-input-required-label 0.3s; }

.form-control.has-error:focus ~ label.error-label {
  display: none; }

.form-control.has-error:focus ~ .question-mark-input .tooltip {
  display: block !important; }

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: rgba(217, 219, 226, 0.5);
  border: 1px solid rgba(217, 219, 226, 0.5);
  opacity: 1; }
  .form-control[disabled] label.control-label.label-expanded, .form-control[disabled] ~ label.control-label,
  .form-control[readonly] label.control-label.label-expanded,
  .form-control[readonly] ~ label.control-label,
  fieldset[disabled] .form-control label.control-label.label-expanded,
  fieldset[disabled] .form-control ~ label.control-label {
    color: rgba(52, 52, 52, 0.3); }

input:disabled ~ label {
  color: #999; }

.form-group.disabled,
.form-control[disabled] ~ label.control-label {
  cursor: not-allowed; }

#selectbox {
  background: url("../images/selectbox.png"); }

.selectpicker.dropdown-menu {
  background-color: white; }

.selectpicker .glyphicon {
  padding: 18px 16px;
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px; }

.bootstrap-select.btn-group {
  background-color: white; }

.bootstrap-select.btn-group .btn .filter-option {
  height: 22px; }

.selectpicker.btn {
  padding: 17px 46px 6px 12px;
  color: #5c5c5c;
  margin-bottom: 0;
  height: 50px; }
  .selectpicker.btn.btn-default.disabled, .selectpicker.btn.btn-default.disabled:hover {
    background-color: rgba(217, 219, 226, 0.5);
    border: 1px solid #d9dbe2;
    opacity: 1;
    color: #999 !important; }
    .selectpicker.btn.btn-default.disabled .glyphicon, .selectpicker.btn.btn-default.disabled:hover .glyphicon {
      color: #a7a7a7; }

.selectFilter.ba_select_panel.col-sm-6 {
  margin-bottom: 20px; }

@-moz-document url-prefix() {
  .bootstrap-select .glyphicon.glyphicon-chevron-down {
    right: -1px; }
  .bootstrap-select .btn.dropdown-toggle,
  select.multiselect + div.btn-group button.multiselect {
    overflow: hidden; }
  .bootstrap-select .btn.dropdown-toggle::-moz-focus-inner,
  select.multiselect + div.btn-group button.multiselect::-moz-focus-inner {
    margin-left: 10px; } }

.bootstrap-select .select--readonly__change-link {
  display: none; }

.bootstrap-select.select--readonly {
  width: auto !important; }

.bootstrap-select.select--readonly > .selectpicker {
  border: 0;
  cursor: auto;
  width: auto;
  padding-right: 12px; }

.bootstrap-select.select--readonly > .selectpicker .glyphicon {
  display: none; }

.bootstrap-select.select--readonly:hover > .selectpicker.hover ~ label {
  color: #7f7f7f; }

.bootstrap-select.select--readonly .select--readonly__change-link {
  display: block; }

textarea.has-error {
  background-position: 0 0; }

textarea.form-control {
  padding-top: 18px; }

.form-group .radio-group {
  max-height: 300px;
  overflow: auto; }

.form-horizontal.form-filter {
  margin-bottom: 0px; }

.bootstrap-select-searchbox input {
  border-radius: 2px !important; }

.input-group .form-control:not(:first-child):not(:last-child).data-amount-to {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  margin-left: 0px; }

.form-group .note {
  padding-left: 6px;
  padding-top: 6px;
  color: #3b4653;
  display: inline-block; }

label.control-label.error-label {
  text-align: left;
  display: block;
  clear: both; }

label.multiline + div + label.error-label {
  clear: none !important;
  margin-left: 0px; }

.form-group .data-value {
  padding-top: 6px;
  display: inline-block; }

.form-group .radio .radio-wrapper {
  display: block; }

.radio .radio-wrapper input[type='radio'] {
  margin-left: -20px; }

@media (max-width: 767px) {
  .form-group .question-mark {
    margin-left: 0px; } }

@media (min-width: 768px) {
  .form-group div[class*='col-'] .form-group {
    margin-bottom: 0px; } }

:not(.bootstrap-select).no-label,
input.no-label {
  padding-top: 10px; }
  :not(.bootstrap-select).no-label::-webkit-input-placeholder,
  input.no-label::-webkit-input-placeholder {
    opacity: 1;
    color: #3b4653; }
  :not(.bootstrap-select).no-label:-moz-placeholder,
  input.no-label:-moz-placeholder {
    opacity: 1;
    color: #3b4653; }
  :not(.bootstrap-select).no-label::-moz-placeholder,
  input.no-label::-moz-placeholder {
    opacity: 1;
    color: #3b4653; }
  :not(.bootstrap-select).no-label .form-control:-ms-input-placeholder,
  input.no-label .form-control:-ms-input-placeholder {
    opacity: 1;
    color: #3b4653; }

fieldset {
  margin-top: 8px; }

legend {
  border: none;
  font-size: 18px;
  margin: 0px 0px 12px; }

.legend-left {
  font-size: 14px;
  margin-top: 12px; }
  @media (min-width: 992px) {
    .legend-left {
      text-align: right;
      margin-top: 0; } }

.checkbox label.semibold {
  font-weight: 600; }

.label-small {
  color: #7f7f7f;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px; }

.label-small + div {
  margin-bottom: 10px; }

.form-group-left > label,
.form-group-left > span {
  line-height: 30px; }

.question-mark.collapsed {
  width: 18px;
  height: 18px;
  background-position: 0px 0px;
  position: relative;
  top: 6px; }

.sticker-errors {
  background: #f06262;
  border-radius: 50%;
  bottom: 20px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  height: 26px;
  position: relative;
  display: inline-block;
  text-align: center;
  left: -20px;
  width: 26px;
  margin-right: -26px;
  line-height: 26px; }

.btn.btn-disabled.errors {
  background: #e9e9e9 !important;
  border-color: #dfdfdf;
  color: #b8b8b8; }

.input-with-action .bootstrap-select {
  padding-right: 23px; }

.input-with-action .small-delete-icon {
  position: absolute;
  right: 3px;
  top: 8px; }

.form-group.read-only {
  margin: 0; }

.form-group.read-only .col-sm-4 {
  padding-top: 6px; }

p.form-control-static {
  line-height: 1.428571429; }

.coupon-details .remove-file {
  display: inline-block;
  height: 35px;
  vertical-align: middle;
  width: 44px;
  text-align: center;
  position: relative;
  top: 6px; }
  .coupon-details .remove-file:hover {
    text-decoration: none; }
  .coupon-details .remove-file:after {
    content: '\e994';
    font-weight: normal;
    font-family: o2font;
    font-size: 24px;
    line-height: 1; }

.coupon-details .coupon-item {
  margin-bottom: 10px; }

.has-tooltip.tooltip-visible ~ label.error-label.red {
  right: 48px; }

input[type='checkbox'].has-error,
input[type='radio'].has-error {
  box-shadow: none; }

input[type='checkbox'] ~ label.error {
  padding-right: 28px;
  position: relative; }

label.error .question-mark {
  width: 20px;
  height: 20px;
  right: 0;
  top: 0; }

label.error .question-mark:before {
  left: 2px;
  top: 2px;
  background-position: -32px 0; }

label.error .tooltip.top {
  bottom: 23px;
  right: -11px; }

.question-mark-required {
  visibility: hidden;
  opacity: 0; }

.has-success ~ .question-mark-required {
  visibility: visible;
  opacity: 1; }

/****************************************************************
 Nested checkbox component
****************************************************************/
.checkbox {
  margin-top: 0px;
  margin-bottom: 12px; }

div.nested-checkboxes .form-group > div.checkbox {
  padding-left: 6px; }

/****************************************************************
 Native select styles from bootstrap select
****************************************************************/
.native-select label.control-label {
  position: static; }

.native-select select.selectpicker {
  width: 100%;
  padding: 9px 12px 7px;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #d9dbe2; }

.optional-label {
  color: #999999; }

.group-label-small {
  padding: 10px 20px 10px 20px;
  border-top: 1px solid #dcdcdc; }

select.selectpicker + div.btn-group button.selectpicker.btn-default,
select.multiselect + div.btn-group button.multiselect.btn-default {
  color: #343434; }

label.control-label.label-expanded,
input[type='text'] ~ label.control-label,
input[type='password'] ~ label.control-label,
input[type='text'] ~ label.control-label,
input[type='password'] ~ label.control-label,
textarea.form-control ~ label.control-label {
  color: #343434; }

/*Fix for IE10, IE11: hides the clear button of a text input when the text input has focus and is not empty.*/
input::-ms-clear {
  display: none; }

/*****************************
* UI autocomplete
*****************************/
.ui-autocomplete-input.focus.ui-autocomplete-loading,
.ui-autocomplete-input.focus {
  border-radius: 2px;
  border-color: #66afe9 !important;
  border-width: 2px;
  outline: 0;
  box-shadow: none; }

.ui-autocomplete {
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-color: #66afe9;
  border-width: 2px;
  outline: 0;
  box-shadow: none;
  border-top: none;
  padding-top: 6px;
  margin-top: -6px; }

.ui-autocomplete-input.autocomplete-opened,
.ui-autocomplete-input.focus.ui-autocomplete-loading.autocomplete-opened {
  border-bottom: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.ui-autocomplete .ui-menu-item .ui-corner-all {
  font-size: 14px;
  color: #3b4653; }

.ui-autocomplete .ui-menu-item .ui-corner-all.ui-state-focus,
.ui-autocomplete .ui-menu-item .ui-corner-all.ui-state-hover {
  border-radius: 0;
  color: white;
  background-color: #428bca;
  border: none;
  margin: 0px; }

.ui-autocomplete-close {
  text-align: right;
  cursor: pointer;
  background-color: white;
  color: #0086c3;
  width: auto;
  font-weight: normal;
  margin-right: 6px;
  margin-bottom: 4px; }

.ui-autocomplete-close.hover {
  cursor: pointer;
  background-color: white;
  color: #0086c3;
  font-weight: normal; }

.ui-autocomplete .ui-menu-item a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.ui-autocomplete-input {
  background-size: 5%;
  background-position: 95% center;
  background-repeat: no-repeat; }

.ui-autocomplete-loading,
.ui-autocomplete-loading.focus {
  background-image: url("../images/eportal/guidelines/ajax-loader.gif") !important; }

/* autocomplete typeahead start*/
.tt-hint,
.twitter-typeahead {
  width: 100%; }

.tt-hint {
  background-color: white;
  border: 1px solid #cacaca;
  box-shadow: none;
  padding: 18px 12px 6px 12px;
  color: #999; }

.tt-dropdown-menu {
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 2px solid #2792D9;
  outline: 0;
  box-shadow: none;
  border-top: none;
  padding-top: 6px;
  margin-top: -6px;
  overflow-y: auto;
  max-height: 258px;
  border-top: 0px; }

.tt-suggestion p {
  padding: 12px .4em;
  margin: 0px; }

.tt-suggestion.tt-cursor {
  cursor: pointer;
  border-radius: 0;
  color: black;
  background-color: #f5f5f5;
  border: none;
  margin: 0px; }

.tt-suggestion.tt-cursor p {
  text-decoration: none;
  padding: 12px .4em;
  min-height: 0; }

/* autocomplete typeahead end*/
form .default-submit {
  top: -1000em;
  position: absolute; }

input.link {
  color: #428bca;
  font-weight: normal;
  text-decoration: none;
  background: none;
  width: auto;
  cursor: pointer;
  line-height: 14px;
  border: none;
  display: inline-block;
  text-align: left;
  margin: 0;
  height: auto;
  *overflow: visible;
  vertical-align: baseline;
  margin-bottom: -4px; }

.form-line textarea {
  width: 310px;
  height: 75px;
  float: left; }

.captcha input {
  margin-right: 5px; }

.nested-checkboxes {
  margin-left: 15px; }

.has-tooltip .selectpicker .glyphicon {
  right: 50px; }

.label-small + p, .label-small + div {
  margin-top: -3px; }

.form-control.search-input {
  padding: 12px 36px 12px 12px;
  color: #000066; }
  .form-control.search-input::-webkit-input-placeholder {
    opacity: 1;
    color: #3b4653; }
  .form-control.search-input:-moz-placeholder {
    opacity: 1;
    color: #3b4653; }
  .form-control.search-input::-moz-placeholder {
    opacity: 1;
    color: #3b4653; }
  .form-control.search-input .form-control:-ms-input-placeholder {
    opacity: 1;
    color: #3b4653; }

.input-group-btn .btn-search {
  margin-left: -50px;
  position: relative;
  z-index: 3;
  border: none;
  border-radius: 0 2px 2px 0;
  padding: 9px 16px;
  background: #0093d5;
  transition: background-color 0.1s ease-in; }
  .input-group-btn .btn-search > [class^="o2f-"] {
    color: white; }
  .input-group-btn .btn-search:hover {
    background: none repeat scroll 0 0 #0070a2;
    transition: background-color 0.2s ease-out; }
  .input-group-btn .btn-search:active, .input-group-btn .btn-search:focus {
    background: #0093d5; }

.videos-wrapper {
  text-align: center; }
  .videos-wrapper .video-wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 24px; }
    .videos-wrapper .video-wrapper .video {
      margin: 12px 0;
      position: relative;
      overflow: hidden; }
      .videos-wrapper .video-wrapper .video .play {
        width: 60px;
        height: 60px;
        background: #00b9b5;
        border-radius: 100%;
        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        .videos-wrapper .video-wrapper .video .play .play-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8.5px 0 8.5px 11px;
          border-color: transparent transparent transparent #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-30%, -55%); }
      .videos-wrapper .video-wrapper .video img {
        transition: transform .2s ease; }
      .videos-wrapper .video-wrapper .video:hover .play {
        background: white; }
        .videos-wrapper .video-wrapper .video:hover .play .play-arrow {
          border-color: transparent transparent transparent #00b9b5; }
      .videos-wrapper .video-wrapper .video:hover img {
        transform: scale(1.2); }
    .videos-wrapper .video-wrapper p {
      margin: 0 auto;
      text-align: left; }
      .videos-wrapper .video-wrapper p > a {
        color: #343434;
        font-weight: 400; }

.breadcrumbs .divider {
  font-family: 'o2font' !important;
  font-size: 24px;
  font-weight: lighter;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #E4E6E7;
  display: inline-flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .breadcrumbs .divider:before {
    content: "\e99c";
    top: 1px;
    position: relative; }

.breadcrumb a:hover {
  color: #005177; }

.breadcrumbs .last {
  color: #717982; }

.breadcrumbs.display_style_6 .divider {
  color: white;
  opacity: 0.3; }
  .breadcrumbs.display_style_6 .divider:before {
    content: "\e99c"; }

.breadcrumbs.display_style_6 a {
  color: white;
  font-weight: normal; }

.breadcrumbs.display_style_6 a:hover {
  text-decoration: underline; }

.breadcrumbs.display_style_6 .last {
  color: white;
  opacity: 0.5; }

.portlet-breadcrumb {
  margin-bottom: 0px; }

.breadcrumbs {
  margin: 0px;
  margin-bottom: 12px; }

/* Tooltip */
.tooltip.top,
.tooltip.bottom {
  opacity: 0;
  width: 296px;
  position: absolute;
  -webkit-filter: none;
          filter: none;
  z-index: 5000;
  cursor: default;
  padding: 0 0 7px 0; }

.icon-info .tooltip,
.btn-tooltip .tooltip {
  display: none; }

.question-mark .tooltip, .tooltip-wrap .tooltip {
  visibility: hidden;
  opacity: 0;
  display: block !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 300ms; }
  .question-mark .tooltip.top, .tooltip-wrap .tooltip.top {
    transform: translateY(-20px); }
  .question-mark .tooltip.bottom, .tooltip-wrap .tooltip.bottom {
    transform: translateY(20px); }

.question-mark:hover .tooltip, .tooltip-wrap:hover .tooltip {
  visibility: visible;
  opacity: 1; }
  .question-mark:hover .tooltip.top, .tooltip-wrap:hover .tooltip.top {
    transform: translateY(0px); }
  .question-mark:hover .tooltip.bottom, .tooltip-wrap:hover .tooltip.bottom {
    transform: translateY(0px); }

.tooltip-standalone .question-mark,
.aui .tooltip-standalone .question-mark {
  position: relative; }
  .tooltip-standalone .question-mark .tooltip,
  .aui .tooltip-standalone .question-mark .tooltip {
    right: 0; }

.tooltip-dots .tooltip.bottom {
  top: 100%; }

.tooltip-dots .tooltip.left {
  left: 0;
  right: auto; }
  .tooltip-dots .tooltip.left .tooltip-arrow {
    left: 15px !important;
    right: auto !important; }

.tooltip-dots .tooltip.right {
  left: auto;
  right: 0; }
  .tooltip-dots .tooltip.right .tooltip-arrow {
    right: 15px !important;
    left: auto !important; }

.question-mark.no-tooltip:before {
  background: none;
  content: ''; }

.question-mark .tooltip,
.aui .question-mark .tooltip {
  display: none;
  opacity: 0;
  border: none;
  border-radius: 2px;
  padding: 0 0 7px 0;
  position: absolute;
  bottom: 50px;
  margin-left: -22px;
  -webkit-filter: none;
          filter: none;
  z-index: 5000;
  bottom: 35px;
  right: 0;
  cursor: help; }
  .question-mark .tooltip.top,
  .aui .question-mark .tooltip.top {
    transform: translateY(-20px); }
  .question-mark .tooltip.bottom,
  .aui .question-mark .tooltip.bottom {
    transform: translateY(-20px); }
  .question-mark .tooltip.right,
  .aui .question-mark .tooltip.right {
    margin-left: 0; }

[class*='icon-'] > .tooltip,
[class^='icon-'] > .tooltip {
  right: -13px; }
  [class*='icon-'] > .tooltip.top,
  [class^='icon-'] > .tooltip.top {
    bottom: 20px; }
  [class*='icon-'] > .tooltip.bottom,
  [class^='icon-'] > .tooltip.bottom {
    top: 23px; }

th .question-mark .tooltip,
.aui th .question-mark .tooltip {
  right: -1px; }
  th .question-mark .tooltip.bottom,
  .aui th .question-mark .tooltip.bottom {
    top: 30px; }
  th .question-mark .tooltip.right,
  .aui th .question-mark .tooltip.right {
    left: 4px;
    right: auto; }
    th .question-mark .tooltip.right .tooltip-arrow,
    .aui th .question-mark .tooltip.right .tooltip-arrow {
      left: 15px;
      right: auto; }

.checkbox input.has-tooltip + label,
.checkbox input[type='checkbox'].tooltip-visible + label {
  padding-right: 10px; }

.checkbox input[type='checkbox']:checked .question-mark {
  display: none; }

.checkbox .question-mark {
  position: absolute;
  width: auto !important;
  height: auto !important;
  top: 0;
  right: 0; }

.checkbox .question-mark .tooltip.top,
.checkbox .question-mark-input .tooltip.top,
.radio .question-mark .tooltip.top {
  bottom: 0px;
  right: -33px; }
  .checkbox .question-mark .tooltip.top.right .tooltip-arrow,
  .checkbox .question-mark-input .tooltip.top.right .tooltip-arrow,
  .radio .question-mark .tooltip.top.right .tooltip-arrow {
    left: 15px;
    right: auto; }

.checkbox .question-mark .tooltip.bottom,
.checkbox .question-mark-input .tooltip.bottom,
.radio .question-mark .tooltip.bottom {
  top: 27px;
  right: -33px; }

.checkbox .question-mark .tooltip.right,
.checkbox .question-mark-input .tooltip.right,
.radio .question-mark .tooltip.right {
  left: -8px;
  right: auto; }
  .checkbox .question-mark .tooltip.right .tooltip-arrow,
  .checkbox .question-mark-input .tooltip.right .tooltip-arrow,
  .radio .question-mark .tooltip.right .tooltip-arrow {
    left: 15px;
    right: auto; }

.has-tooltip.has-success ~ .question-mark > .tooltip {
  display: none !important; }

.question-mark .tooltip p {
  color: white;
  font-size: 14px;
  border: none;
  box-shadow: none; }

.tooltip .arrow {
  background: url("../images/tooltip-arrow.png") no-repeat;
  width: 15px;
  height: 16px;
  position: absolute;
  margin: 10px 0 0 11px; }

.tooltip.right .tooltip-arrow {
  top: auto;
  border-right-color: transparent;
  left: 20px;
  right: auto; }

.tooltip.bottom.right .tooltip-arrow {
  top: -2px;
  bottom: auto; }

.tooltip-inner {
  font-size: 14px;
  text-align: left;
  padding: 6px 12px;
  background-color: #5c5c5c; }

.question-mark-input .tooltip.top {
  bottom: 43px;
  right: 0px; }

.tooltip.top .tooltip-arrow {
  border-width: 8px 8px 0;
  bottom: 1px; }

.question-mark-input .tooltip.bottom {
  top: 47px;
  right: 0px; }

.tooltip.bottom .tooltip-arrow {
  border-width: 0 8px 8px;
  top: -6px; }

.tooltip.top .tooltip-arrow,
.tooltip.bottom .tooltip-arrow {
  left: auto;
  right: 17px;
  border-top-color: #5c5c5c;
  border-bottom-color: #5c5c5c; }

.tooltip-wrap {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.tooltip-wrap .tooltip {
  position: absolute;
  bottom: 14px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: var(--echt-transition);
  transition-duration: 300ms; }
  .tooltip-wrap .tooltip.top {
    transform: translateY(-20px); }
  .tooltip-wrap .tooltip.bottom {
    transform: translateY(20px); }

.tooltip-wrap .tooltip-dots {
  border-bottom: 1px dotted #343434;
  line-height: 1.4; }

.tooltip-wrap.has-tooltip-heading .tooltip {
  bottom: 36px; }

.tooltip-inner p,
.tooltip-inner span {
  color: white; }

.tooltip-inner a {
  color: white;
  text-decoration: underline; }

.tooltip-inner p:last-child {
  margin-bottom: 0px; }

.tooltip-inline {
  border-bottom: 1px dotted #73b0d9;
  color: #0d8fc9;
  text-decoration: none;
  display: inline; }

.tooltip-inline .tooltip {
  bottom: 100%;
  right: auto; }

.tooltip-inline .tooltip-arrow {
  display: none; }

.question-mark:not(.detail-fivestar-element) {
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: absolute; }

.question-mark:before {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 15px;
  z-index: 3;
  color: #aaa;
  font-size: 20px;
  font-family: o2font;
  content: '\e9a4';
  font-weight: normal; }

.question-mark-input {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 1;
  line-height: 50px; }

.twitter-typeahead .question-mark-input {
  right: 1px; }

.form-control.prepended ~ .question-mark {
  right: 0px; }

.form-control.appended ~ .question-mark {
  right: 45px; }

.has-success ~ .question-mark:before {
  content: '\e9aa';
  color: #3bb237; }

.has-error ~ .question-mark:before {
  content: '\e9a7';
  opacity: 1;
  color: #e82b3b;
  -webkit-animation: grow-questionmark-error 0.3s;
          animation: grow-questionmark-error 0.3s;
  transform-origin: center; }

.has-success ~ .question-mark:before {
  content: '\e9aa';
  opacity: 1;
  color: #3bb237;
  -webkit-animation: grow-questionmark-success 0.3s;
          animation: grow-questionmark-success 0.3s;
  transform-origin: center; }

.has-error ~ .question-mark .tooltip-inner {
  background-color: #d32c23; }

.has-error ~ .question-mark .tooltip-arrow {
  border-top-color: #d32c23;
  border-bottom-color: #d32c23; }

.tooltip.top.top-left .tooltip-arrow {
  left: 10px;
  right: auto;
  margin-left: 0px; }

.tooltip.bottom.bottom-left .tooltip-arrow {
  left: 10px;
  right: auto;
  margin: 0px; }

.tooltip.left.top .tooltip-arrow {
  bottom: 0px;
  top: auto;
  border-left-color: transparent; }

.tooltip.left.bottom .tooltip-arrow {
  top: -2px;
  border-left-color: transparent; }

.check-mark {
  width: 37px;
  height: 28px;
  background: url("../images/check-mark.png") center no-repeat; }

.question-mark.visible-xs-inline-block {
  vertical-align: top; }

.divider {
  margin: 60px auto; }

.divider-gray {
  height: 1px;
  background: #d9dbe2;
  width: 100%; }

.divider-gray2 {
  height: 25px;
  background: url("../images/divider-gray.png") no-repeat center; }

.divider-basic {
  background: #bababa;
  height: 1px;
  margin: 30px auto; }

.num-select {
  margin: 10px 0; }

.num-select.ui-widget-content {
  border: none; }

.num-select.ui-corner-all {
  height: 2px;
  border-radius: 2px;
  background: #d9dbe2; }

.range-wrapper .ui-slider .ui-slider-handle,
.num-select.ui-slider .ui-slider-handle {
  width: 20px;
  height: 20px;
  background: white;
  border: 2px solid #0093d5;
  border-radius: 50%;
  margin-top: -4px;
  outline: none;
  cursor: pointer;
  top: -5px;
  transition: transform 0.1s ease; }
  .range-wrapper .ui-slider .ui-slider-handle > .range-tooltip,
  .num-select.ui-slider .ui-slider-handle > .range-tooltip {
    -webkit-animation: range-slide-tooltip-top 0.2s;
            animation: range-slide-tooltip-top 0.2s;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: var(--echt-transition); }
  .range-wrapper .ui-slider .ui-slider-handle.ui-state-hover, .range-wrapper .ui-slider .ui-slider-handle.ui-state-active,
  .num-select.ui-slider .ui-slider-handle.ui-state-hover,
  .num-select.ui-slider .ui-slider-handle.ui-state-active {
    transform: scale(1.2); }
    .range-wrapper .ui-slider .ui-slider-handle.ui-state-hover > .range-tooltip:not(:empty), .range-wrapper .ui-slider .ui-slider-handle.ui-state-active > .range-tooltip:not(:empty),
    .num-select.ui-slider .ui-slider-handle.ui-state-hover > .range-tooltip:not(:empty),
    .num-select.ui-slider .ui-slider-handle.ui-state-active > .range-tooltip:not(:empty) {
      opacity: 1;
      visibility: visible;
      -webkit-animation: range-slide-tooltip-bottom 0.2s;
              animation: range-slide-tooltip-bottom 0.2s;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      transition-timing-function: var(--echt-transition); }
  .range-wrapper .ui-slider .ui-slider-handle > .range-tooltip,
  .num-select.ui-slider .ui-slider-handle > .range-tooltip {
    visibility: hidden;
    opacity: 0;
    background: #0093d5;
    padding: 1px 8px;
    border-radius: 2px;
    width: auto;
    display: table;
    color: white;
    position: absolute;
    font-size: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -190%); }
    .range-wrapper .ui-slider .ui-slider-handle > .range-tooltip:after,
    .num-select.ui-slider .ui-slider-handle > .range-tooltip:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #0093d5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 210%); }

.range-wrapper .to-text, .range-wrapper .from-text {
  color: #bbb; }

.num-select span {
  position: relative;
  top: -36px; }

.num-select-wrapper {
  padding-top: 20px; }
  .num-select-wrapper .row:first-child {
    margin-bottom: 12px; }

.num-select-wrapper .row .form-group {
  float: none; }

.range-wrapper {
  border-radius: 10px;
  padding-top: 20px;
  padding-left: 9px;
  padding-right: 19px;
  min-height: 44px; }

.slider-range.ui-widget-content {
  background: #d9dbe2;
  border: none;
  height: 2px; }

.range-wrapper .data-amount-from,
.range-wrapper .data-amount-to {
  width: 57px;
  color: #5c5c5c; }

.range-wrapper .splitter-gray {
  width: 100%; }

.range-wrapper .slider-range span:not(.from-text):not(.to-text):not(.ui-slider-handle) {
  position: absolute;
  top: -38px;
  font-size: 14px; }

.range-wrapper .input-group {
  margin-top: 20px;
  padding-left: 10px; }

.range-wrapper .data-amount-from + .input-group-addon,
.range-wrapper .data-amount-to + .input-group-addon {
  border-left: none;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #5c5c5c; }

.range-wrapper .splitter-gray {
  background: white;
  border: none;
  position: relative; }

.range-wrapper .splitter-gray hr {
  position: absolute;
  top: 12px;
  background: #d9dbe2;
  height: 4px;
  width: 100%;
  left: 0px;
  margin-top: 10px;
  border: none; }

.range-wrapper .slider-range span.left {
  left: -8px; }

.range-wrapper .slider-range span.right {
  right: -12px; }

.slider-range .ui-slider-range {
  background: #0093d5; }

.slider-range.ui-widget-header {
  background: #0093d5; }

.range-wrapper .range-wrapper .form-group {
  width: 48%; }

.multiplicator {
  display: inline-block;
  border: 1px solid #cacaca;
  border-radius: 2px;
  overflow: hidden;
  min-width: 65px; }

.multiplicator > div,
.multiplicator > input {
  float: left;
  height: 42px;
  line-height: 32px; }

.multiplicator .subtract {
  background: white;
  border-right: 1px solid #cacaca; }

.multiplicator .add {
  background: white;
  border-left: 1px solid #cacaca; }

.multiplicator > div > a {
  display: block;
  padding: 5px 5px;
  width: 43px;
  text-align: center; }

.mobile .multiplicator > div > a {
  display: block;
  padding: 5px 10px; }

.mobile .multiplicator .number-current {
  width: 30px; }

.multiplicator .number-current {
  background: #f2f3f5;
  min-width: 21px;
  padding: 5px 4px;
  border: none;
  text-align: center;
  width: 42px; }

.multiplicator .glyphicon {
  top: -1px;
  font-size: 9px; }

.multiplicator .number-current[disabled] {
  background-color: white;
  color: #555; }

.multiplicator.disabled .glyphicon {
  color: rgba(156, 202, 234, 0.99); }

.multiplicator .number-current > input {
  background: transparent;
  border: none; }

.multiplicator .disabled a {
  color: #cacaca;
  cursor: default; }

.form-group .fileitem .btn {
  font-size: 14px; }

.btn.fileinput-button .fileinput-icon {
  display: inline-block;
  margin-right: 4px;
  line-height: 1;
  position: relative;
  top: 2px; }
  .btn.fileinput-button .fileinput-icon:after {
    font-family: o2font;
    content: '\e9ad';
    font-size: 26px;
    font-weight: 400;
    line-height: 1; }

.fileitem.uploaded .file {
  background: #d5f2ff; }

.fileitem.uploading .file {
  height: 70px; }

.fileitem .file {
  border: 1px solid #d5f2ff;
  border-radius: 2px; }

.fileitem .file a {
  text-decoration: none; }

.fileitem {
  margin-bottom: 12px;
  position: relative; }

.fileitem .btn-remove {
  cursor: default;
  position: absolute;
  width: 44px;
  height: 48px;
  right: 1px;
  top: 1px;
  border-radius: 0px 2px 2px 0px;
  z-index: 1; }

.fileitem .btn-remove > .btn {
  border: 0px; }

.fileitem .btn-remove:hover .btn-panel {
  display: block; }

.fileitem .btn-remove .btn-small {
  border: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 8px;
  margin: 0;
  line-height: 1;
  padding-right: 8px;
  font-size: 13px;
  background: transparent;
  min-width: auto;
  width: 44px;
  height: 50px; }
  .fileitem .btn-remove .btn-small > span {
    display: none; }
  .fileitem .btn-remove .btn-small:after {
    display: inline-block;
    content: '\e994' !important;
    font-size: 24px;
    font-family: o2font !important;
    line-height: 1;
    position: relative;
    top: 4px;
    color: #999; }

.fileitem .btn-remove > .btn:hover,
.fileitem .btn-remove:hover > .btn {
  background-color: #5c5c5c;
  transition: none; }

.fileitem.uploading > .file > .link-file > .itemname {
  top: -8px; }

.fileitem.uploading .file-upload-progress {
  position: absolute;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  top: 48px; }

.fileitem.uploading .file-upload-progress > .progress-bar {
  width: 100%;
  height: 6px; }

.fileitem.uploading .file-upload-progress > .progress-bar > .fill {
  background-color: #0093d5;
  height: 6px; }

.fileitem .file > .btn.btn-white {
  border: none; }

.btn.btn-white.fileinput-button {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 12px;
  border-style: dashed;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px; }

.btn.fileinput-button.fileinput-button--small {
  min-height: 44px;
  margin-top: 0px;
  padding: 4px 4px 4.2px 4px; }

.btn.fileinput-button.fileinput-button--small .fileinput-icon {
  width: 22px;
  height: 29px; }

.btn.fileinput-button.has-error {
  border: 1px dashed #e82b3b;
  box-shadow: none;
  flex-wrap: wrap; }
  .btn.fileinput-button.has-error > .red {
    flex-grow: 1;
    width: 100%; }

.btn.fileinput-button.has-error:hover,
.btn.fileinput-button-hovered.has-error {
  background-color: rgba(232, 43, 59, 0.05);
  box-shadow: none; }

.btn.fileinput-button.has-error .fileinput-lable,
.btn.fileinput-button.has-error .fileinput-icon {
  color: #0093d5;
  font-weight: 600; }

.btn.fileinput-button:hover,
.btn.fileinput-button-hovered {
  background: #e4f7ff;
  border-radius: 2px; }

.btn.fileinput-button .fileinput-lable {
  font-weight: 600; }

.btn.fileinput-button.has-error .fileinput-lable {
  top: 0px; }

.fileitem .btn-left.link-file {
  padding: 0.3rem 0;
  width: 100%;
  text-align: left;
  border-radius: 2px;
  background: none; }

.fileitem .btn-left.link-file.btn-white:hover {
  background-color: transparent; }

.fileitem .btn-left.link-file .fileupload-icon {
  margin: 0px 12px;
  display: inline-block; }
  .fileitem .btn-left.link-file .fileupload-icon:after {
    content: '\e948';
    font-family: o2font;
    font-size: 26px;
    position: relative;
    top: 3px; }

.fileitem .btn-left.link-file .fileupload-filename {
  position: absolute;
  top: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 88px);
  white-space: nowrap;
  font-weight: 600; }

.fileupload--file-type {
  position: absolute;
  right: 52px;
  top: 12px;
  color: #0093d5; }

.fileitem .btn-right.remove-icon {
  display: none; }

@media (max-width: 979px) {
  .fileitem .btn-right.remove-icon {
    width: auto;
    width: initial; } }

.btn.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
  -ms-filter: 'alpha(opacity=0)';
  filter: 'alpha(opacity=0)'; }

.multiitem {
  margin-bottom: 12px; }

.multiitem .fileitem {
  margin-bottom: 0px; }

.multiitem .fileitem:not(:last-child) .file {
  border-bottom: none;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.multiitem .fileitem:not(:first-child) .file {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: -1px; }

.multiitem .fileitem:not(:last-child) .btn-right.remove-icon {
  border-bottom-right-radius: 0px; }

.multiitem .fileitem:not(:first-child) .btn-right.remove-icon {
  border-top-right-radius: 0px; }

.fileitem .btn-panel {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

/* End of multi item component*/
.read-more-wrapper.has-overflow {
  overflow: hidden;
  position: relative; }

.read-more-wrapper .read-more__btn {
  position: absolute;
  bottom: 0;
  background: white;
  background: linear-gradient(0deg, white 0%, white 56%, rgba(255, 255, 255, 0.403799) 100%);
  display: inline-block;
  width: 100%;
  height: 40px;
  padding-top: 22px; }

.read-more-wrapper.opened {
  max-height: none; }
  .read-more-wrapper.opened .read-more__btn {
    position: static;
    padding-top: 0;
    background: transparent;
    height: auto; }

/* Contains legacy styles */
/*Colors - BEGIN*/
/**** Colors - end *****/
:root {
  --echt-transition: cubic-bezier(0.25, 0.1, 0.25, 1); }

.glyphicon {
  font-family: 'o2font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.glyphicon.glyphicon-chevron-down::before {
  content: '\e99f'; }

.glyphicon.glyphicon-remove {
  font-size: 23px;
  font-weight: bold;
  position: relative;
  top: 2px; }

.input-group-btn .btn-search {
  font-size: 19px; }

.input-group-btn .btn-search .glyphicon-search {
  color: #0093d5; }

.glyphicon.glyphicon-search::before {
  content: '\e91b'; }

.glyphicon.glyphicon-remove::before {
  content: '\e994'; }

.glyphicon.glyphicon-remove-circle::before {
  content: '\e997'; }

.glyphicon.glyphicon-chevron-left::before {
  content: '\e99e'; }

.glyphicon.glyphicon-chevron-right::before {
  content: '\e99c'; }

.glyphicon.glyphicon-plus::before {
  content: '\e992'; }

.tab-content-filter .btn [class^="o2f-"],
.tab-content-filter .btn .glyphicon {
  font-size: 17px;
  font-weight: bold; }

.btn-group .btn .glyphicon {
  font-size: 16px;
  font-weight: 600; }

.btn-group .btn-blue .glyphicon,
.btn-group .btn-red .glyphicon,
.btn-group .btn-green .glyphicon {
  color: white; }

.btn-group .btn-white .glyphicon {
  color: #0093d5; }

.glyphicon-chevron-down {
  font-size: 12px; }
